import React from "react";
// import { Link } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import { APP_NAME } from "../../helpers/Constants";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ position: "fixed" }}>
        <Container fluid>
          <Row>
            <Col sm={6}>
              {new Date().getFullYear()} © {APP_NAME}.
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Crafted by <a href="https://abacitechs.com/">abaci</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
