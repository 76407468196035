import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { APP_NAME } from "../../../helpers/Constants";
import MiniWidgets from "./MiniWidgets";
import { authAxios } from "../../../axiosInstance";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";

export default function Index() {

  const logoutHandler = useUnAuthorizedHandler();

  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Dashboard", link: "#" },
    ],
   
  });

  const [count,setCount] = useState([
    {
      id: 1,
      icon: "ri-team-fill",
      title: "Total Clients",
      value: 0,
      desc: "",
    }, {
      id: 5,
      icon: "ri-device-line",
      title: "Devices",
      value: 0,
      desc: "",
    },
    {
      id: 2,
      icon: "ri-building-2-line",
      title: "Total Sites",
      value: 0,
      desc: "",
    },
    {
      id: 3,
      icon: "ri-vidicon-line",
      title: "Total Sensors",
      value: 0,
      desc: "",
    },
    {
      id: 4,
      icon: "ri-wallet-2-line",
      title: "Revenue",
      value: 0,
      desc: "",
    }
  ]);

  useEffect(() => {
    const url = `/abacicount/dashboard`
    authAxios
    .get(url)
    .then((res) => {
      setCount( [{
        id: 1,
        icon: "ri-team-fill",
        title: "Total Clients",
        value: res?.data?.active_client + ' / ' +res?.data?.total_client,
        desc: "Active / Total Clients",
      },
       {
        id: 5,
        icon: "ri-device-line",
        title: "Devices",
        value: res?.data?.active_devices + ' / ' + res?.data?.total_devices,
        desc: "Active / Total Devices",
      },
      {
        id: 2,
        icon: "ri-building-2-line",
        title: "Total Sites",
        value: res?.data?.total_no_of_sites,
        desc: "Total Sites",
      },
      {
        id: 3,
        icon: "ri-vidicon-line",
        title: "Total Sensors",
        value: res?.data?.total_no_of_sensors,
        desc: " Total Sensors",
      },
      {
        id: 4,
        icon: "ri-wallet-2-line",
        title: "Revenue",
        value: '$ ' +res?.data?.revenue,
        desc: " Revenue",
      }])
    })
    .catch((err) => {
      Toastr('error',Error(err,logoutHandler))
    })
  }, [])


  

  useEffect(() => {
    tempTheme();
  }, []);

  const tempTheme = () => {
    if (document.body) {
      document.body.setAttribute("data-sidebar", "dark");
    }
  };

  return (
    <div className='page-content'>
      <Container fluid>
        <Breadcrumbs
          title='Dashboard'
          breadcrumbItems={dashboardState.breadcrumbItems}
        />

            <Row xl={5}>
              <MiniWidgets data={count}/>
            </Row>
    </Container>
    </div>
  );
}
