import React, { useState, useRef, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { APP_NAME, currency } from "../../../helpers/Constants";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { authAxios } from "../../../axiosInstance";
import { useParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import IconButton from "@mui/material/IconButton";
import { unsecuredCopyToClipboard } from "../../../helpers/functions";
import { setDeviceDetail } from "../../../store/client";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import TableComponent from "./tableComponent";
import AbaciLoader from "../../../components/AbaciLoader/AbaciLoader";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";
import moment from "moment";
import LeftDetailComponent from "../../../components/DeviceDetail/LeftDetailComponent";

export default function DeviceDetails() {
  const logoutHandler = useUnAuthorizedHandler();
  const deviceDetail = useSelector((state) => state.ClientSlice.deviceDetail);
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const [revenue, setRevenue] = useState("");

  const tableRef = useRef();
  const { id } = useParams();
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "#" },
      { title: "Clients", link: "/clients" },
      { title: "Device Details", link: "#" },
    ],
  });

  useEffect(() => {
    // window.addEventListener('resize',resizeHandler)

    const url = `/abacicount/clientdevice/${id}`;
    authAxios
      .get(url)
      .then((response) => {
        dispatch(setDeviceDetail(response.data));
      })
      .catch((error) => {
        const err_msg = Error(error, logoutHandler);
        Toastr("error", err_msg);
      });

    const revenue_url = `/abacicount/devicerevenue?device=${id}`;
    authAxios
      .get(revenue_url)
      .then((response) => {
        setRevenue(response.data.revenue);
      })
      .catch((error) => {
        const err_msg = Error(error, logoutHandler);
        Toastr("error", err_msg);
      });
    return () => {
      dispatch(setDeviceDetail(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    tableRef.current.onQueryChange({ page: 0 });
  };

  const clickHandler = async (data) => {
    if (window.isSecureContext && navigator.clipboard) {
      return await navigator.clipboard.writeText(data);
    } else {
      unsecuredCopyToClipboard(data);
    }
  };

  const handleCopyClick = (text) => {
    // Asynchronously call copyTextToClipboard
    clickHandler(text)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  let tab_style = {
    textTransform: "none",
    minWidth: "125px",
    width: "130px",
    fontSize: "14px",
  };

  const dateHandler = (date) => {
    const currentDate = new Date();
    const yourDate = new Date(date);
    if (yourDate > currentDate) {
      return false;
    } else {
      return true;
    }
  };

  if (deviceDetail === null) return <AbaciLoader />;
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Device Details"}
              breadcrumbItems={dashboardState.breadcrumbItems}
            />

            {/* <p style={{ marginTop: "-15px" }}>
              Address : {clientDetail?.address ? clientDetail?.address : "-"}
            </p> */}
            {deviceDetail?.latest_product_activation_key && (
              <div className="d-flex gap-1">
                <p style={{ marginTop: "-10px" }}>
                  Device Key:{" "}
                  {deviceDetail?.latest_product_activation_key?.slice(0, 12) +
                    "...."}
                </p>
                <Tooltip title={isCopied ? "Text Copied!!" : "Copy"} arrow>
                  <IconButton
                    size="small"
                    style={{ marginTop: "-24px" }}
                    onClick={() =>
                      handleCopyClick(
                        deviceDetail?.latest_product_activation_key
                      )
                    }
                  >
                    <ContentCopyIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            <div
              style={{
                position: "relative",
                left: "70%",
                top: "-50px",
                marginBottom: "-40px",
              }}
            >
              <p>Total Revenue</p>
              <h4 style={{ marginTop: "-10px" }}>
                {currency + " "}
                {revenue ? revenue : 0}
              </h4>
            </div>

            <Row>
              <Col xl={4}>
                <Card style={{ minHeight: "545px" }}>
                  <CardBody>
                    <LeftDetailComponent
                      tableRef={tableRef}
                      activeTab={value}
                      setRevenue={setRevenue}
                      // cloud_sync={clientDetail?.cloud_sync_valid_up_to}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xl={8}>
                <Card style={{ minHeight: "545px" }}>
                  <CardBody>
                    <Box>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab label="Activity Log" style={tab_style} wrapped />
                        {/* <Tab label="Connection Log"  style={tab_style} wrapped/> */}
                        <Tab
                          label="Site Details"
                          style={tab_style}
                          wrapped
                          disabled={
                            deviceDetail?.cloud_sync_valid_up_to === null ||
                            !deviceDetail.online_status ||
                            (deviceDetail?.cloud_sync_valid_up_to !== null &&
                              dateHandler(
                                moment(
                                  deviceDetail?.cloud_sync_valid_up_to
                                ).format("YYYY-MM-DD")
                              ))
                          }
                        />
                        <Tab
                          label="Zone Details"
                          style={tab_style}
                          wrapped
                          disabled={
                            deviceDetail?.cloud_sync_valid_up_to === null ||
                            !deviceDetail.online_status ||
                            (deviceDetail?.cloud_sync_valid_up_to !== null &&
                              dateHandler(
                                moment(
                                  deviceDetail?.cloud_sync_valid_up_to
                                ).format("YYYY-MM-DD")
                              ))
                          }
                        />
                        <Tab
                          label="Sensor Details"
                          style={tab_style}
                          wrapped
                          disabled={
                            deviceDetail?.cloud_sync_valid_up_to === null ||
                            !deviceDetail.online_status ||
                            (deviceDetail?.cloud_sync_valid_up_to !== null &&
                              dateHandler(
                                moment(
                                  deviceDetail?.cloud_sync_valid_up_to
                                ).format("YYYY-MM-DD")
                              ))
                          }
                        />
                        <Tab label="Orders" style={tab_style} wrapped />
                        <Tab label="Report Log" style={tab_style} wrapped />
                      </Tabs>
                    </Box>

                    <TableComponent activeTab={value} tableRef={tableRef} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
