import React from "react";
import Login from "../containers/Pages/Authentication/Login";
import Dashboard from "../containers/Pages/Dashboard";
// import Test from "../helpers/Test";
import Clients from "../containers/Pages/Clients";
// import ClientLog from "../containers/Pages/Reports";
import ClientDetail from "../containers/Pages/Clients/ClientDetail";
import Users from "../containers/Pages/Users";
import Versions from "../containers/Pages/Versions";
import DeviceDetails from "../containers/Pages/Clients/deviceDetails";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    sidebar_presence: true,
    icon_class: "ri-dashboard-line",
    name: "Dashboard",
    layout_wrapper_required: true,
  },
  {
    path: "/clientdetails/:id",
    component: <ClientDetail />,
    sidebar_presence: false,
    icon_class: "ri-user-line",
    name: "Client Details",
    layout_wrapper_required: true,
  },
  {
    path: "/devicedetails/:id",
    component: <DeviceDetails />,
    sidebar_presence: false,
    icon_class: "ri-user-line",
    name: "Device Details",
    layout_wrapper_required: true,
  },
  {
    path: "/clients",
    component: <Clients />,
    sidebar_presence: true,
    icon_class: "ri-user-line",
    name: "Clients",
    layout_wrapper_required: true,
  },

  // {
  //   path: "/#",
  //   component: "",
  //   sidebar_presence: true,
  //   icon_class: "ri-folder-chart-fill",
  //   icon_class2: "ri-arrow-down-s-fill",

  //   name: "Report",
  //   layout_wrapper_required: true,
  //   submenu: true,
  //   submenu_components: [
  //   ]
  // },
  {
    path: "/users",
    component: <Users />,
    sidebar_presence: true,
    icon_class: "ri-group-line",
    name: "Users",
    layout_wrapper_required: true,
  },
  {
    path: "/versions",
    component: <Versions />,
    sidebar_presence: true,
    icon_class: "ri-settings-3-line",
    name: "Versions",
    layout_wrapper_required: true,
  },
];

const publicRoutes = [
  { path: "/", component: <Login /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
