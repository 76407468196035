import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import GraphLoader from "./GraphLoader/GraphLoader";

function Modals(props) {
  const [backdrop] = useState(true);

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      scrollable
      size={props.size ? props.size : "lg"}
      toggle={props.toggle}
      innerRef={props.ref}
      zIndex={"1002"}
      backdrop={backdrop}
      fade={true}
      fullscreen={props.fullscreen ? props.fullscreen : false}
    >
      {props.loader ? (
        <div style={{ zIndex: "9999" }}>
          {" "}
          <GraphLoader />{" "}
        </div>
      ) : null}

      <ModalHeader toggle={props.toggle}>
        {props.title}
        {props.infoIcon && props.infoIcon}
      </ModalHeader>
      <ModalBody>{props.children}</ModalBody>
    </Modal>
  );
}

export default Modals;
