import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import Modals from "../Modal";
import { authAxios } from "../../axiosInstance";
import Error from "../../helpers/Error";
import { ErrorAlert, Toastr } from "../../helpers/Alert";
import { numberInvalidCharacterPreventer } from "../../helpers/functions";
import { useDispatch, useSelector } from "react-redux";
import { setDeviceDetail } from "../../store/client";
import GraphLoader from "../GraphLoader/GraphLoader";
import { useParams } from "react-router-dom";
import useUnAuthorizedHandler from "../../hooks/useUnAuthorizedHandler";
import { orderTypeOption } from "../../helpers/Constants";
import StartdateEndDate from "../StartdateEndDate";
import moment from "moment";
import { subscriptionYears } from "../../helpers/Constants";

function InitiateNewOrder(props) {
  const logoutHandler = useUnAuthorizedHandler();
  const deviceDetail = useSelector((state) => state.ClientSlice.deviceDetail);
  const totalSitesLabel =
    deviceDetail?.total_no_of_sites === 0
      ? "Please Add Required Number of Sites"
      : `${deviceDetail?.total_no_of_sites} Sites Are Added ,Please Add Additional Sites Required`;

  const totalSensorsLabel =
    deviceDetail?.total_no_of_sensors === 0
      ? "Please Add Required Number of Sensors"
      : `${deviceDetail?.total_no_of_sensors} Sensors Are Added ,Please Add Additional Sensors Required`;

  const cloudSyncLabel =
    deviceDetail?.cloud_sync_valid_up_to === null
      ? "Please provide a Cloud Sync expiry date"
      : `Cloud Sync is valid up to - ${deviceDetail?.cloud_sync_valid_up_to} , Please Provide an extended date in order to extend the validity`;

  const [orderType, setOrderType] = useState(null);
  const [versionList, setVersionList] = useState([]);
  const [isVersionListLoading, setIsVersionListLoading] = useState(true);

  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isFirstOrder, setIsFirstOrder] = useState(true);

  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setwaitingForAxios(true);
    let formData = new FormData();
    formData.append("order_type", orderType.value);
    formData.append("device", id);
    formData.append("amount", data.amount);
    formData.append("subscription_year", data.subscription_year.value);
    formData.append("cloud_sync_valid_up_from", data.cloud_sync_valid_up_from);
    formData.append("cloud_sync_valid_up_to", data.cloud_sync_valid_up_to);

    formData.append(
      "no_of_sites",
      data.no_of_sites !== "" ? data.no_of_sites : 0
    );
    formData.append(
      "no_of_sensors",
      data.no_of_sensors !== "" ? data.no_of_sensors : 0
    );
    // if (orderType.value === "Cloud" || orderType.value === "Both") {
    //   formData.append("cloud_sync_valid_up_to", data.expiry_date);
    // }

    // if(orderType.label === 'On-premises'){
    //     formData.append("no_of_sites", data.no_of_sites);
    //     formData.append("no_of_sensors", data.no_of_sensors);
    //     formData.append("cloud_sync_valid_up_to", '');
    // }
    // else if(orderType.label === 'Cloud Sync'){
    //     formData.append("cloud_sync_valid_up_to", data.expiry_date);
    //     formData.append("no_of_sites", 0);
    //     formData.append("no_of_sensors", 0);
    // }
    // else{
    //     formData.append("no_of_sites", data.no_of_sites);
    //     formData.append("no_of_sensors", data.no_of_sensors);
    //     formData.append("cloud_sync_valid_up_to", data.expiry_date);
    // }

    let url = "/abacicount/deviceorderlistcreate";
    authAxios
      .post(url, formData)
      .then((response) => {
        // dispatch(addNewClients(response.data))
        // props.tableRef.current.onQueryChange();

        let dataToBeSendToRedux = {
          total_no_of_sensors:
            Number(deviceDetail?.total_no_of_sensors) +
            Number(response.data.no_of_sensors),
          total_no_of_sites:
            Number(deviceDetail?.total_no_of_sites) +
            Number(response.data.no_of_sites),
          latest_product_activation_key: response.data.product_activation_key,
          cloud_sync_valid_up_to:
            response.data.cloud_sync_valid_up_to !== null
              ? response.data.cloud_sync_valid_up_to
              : deviceDetail?.cloud_sync_valid_up_to,
        };

        dispatch(setDeviceDetail({ ...deviceDetail, ...dataToBeSendToRedux }));

        props.setRevenue(
          (previousState) => Number(previousState) + Number(data.amount)
        );

        if (props.activeTab === 4 || props.activeTab === 0) {
          props.tableRef.current.onQueryChange({ page: 0 });
        }
        setwaitingForAxios(false);
        Toastr("success", "New order successfully created!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error, logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  // useEffect(() => {
  //   let url = `/abacicount/version_dropdown_list`;
  //   authAxios
  //     .get(url)
  //     .then((res) => {
  //       const temp = res.data.map((data) => ({
  //         ...data,
  //         label: data.version,
  //         value: data.id,
  //       }));
  //       setVersionList(temp);
  //     })
  //     .catch((error) => {
  //       let error_msg = Error(error, logoutHandler);
  //       ErrorAlert(error_msg);
  //     })
  //     .finally(() => {
  //       setIsVersionListLoading(false);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const dateValidater = () => {
    const currentDate = new Date();
    const ExpDate = new Date(deviceDetail?.cloud_sync_valid_up_to);

    setValue(
      "cloud_sync_valid_up_from",
      ExpDate > currentDate
        ? moment(ExpDate).add(1, "days").format("YYYY-MM-DD")
        : moment(currentDate).format("YYYY-MM-DD")
    );
  };
  const orderTypeOnchange = (orderType) => {
    setOrderType(orderType);
    reset({
      expiry_date: "",
      no_of_sensors: "",
      no_of_sites: "",
    });

    if (orderType.value === "Upgrade") {
      setIsVersionListLoading(true);
      let url = `/abacicount/latest_version`;
      authAxios
        .get(url)
        .then((res) => {
          setValue("version", {
            value: res.data.id,
            label: res.data.version,
          });
        })
        .catch((error) => {
          let error_msg = Error(error, logoutHandler);
          ErrorAlert(error_msg);
        })
        .finally(() => {
          setIsVersionListLoading(false);
        });
    }
  };

  const endDateHandlerFunction=(startDate)=>{
   const Year= getValues('subscription_year')?.value
    setValue("cloud_sync_valid_up_to",moment(startDate).add(Year, "year").format("YYYY-MM-DD"))

  }

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)} style={{ margin: "20px" }}>
        <div style={{ minHeight: "400px" }}>
          {loader ? (
            <GraphLoader />
          ) : (
            <>
              {" "}
              <FormGroup style={{ marginBottom: "5px" }}>
                <Label sm={3}>Order Type</Label>
                <Col>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select Order Type"
                    isClearable={false}
                    isSearchable={false}
                    onChange={(value) => {
                      orderTypeOnchange(value);
                      dateValidater();
                    }}
                    value={orderType}
                    options={orderTypeOption}
                  />
                </Col>
              </FormGroup>
              {orderType !== null && (
                <>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={3}>Subscription</Label>
                    <Col>
                      <Controller
                        name="subscription_year"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Select year"
                              isClearable={false}
                              isSearchable={false}
                              onChange={(values) => {
                                field.onChange(values);
                                const startDate=getValues("cloud_sync_valid_up_from")
                                endDateHandlerFunction(startDate)
                              }}
                         
                              options={subscriptionYears}
                              value={getValues("subscription_year")}
                             
                            />

                            {errors.subscription_year && (
                              <span style={{ color: "red" }}>
                                *This field is required
                              </span>
                            )}
                          </>
                        )}
                      />
                     
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>Amount</Label>
                    <Col>
                      <input
                        placeholder="Amount"
                        type="number"
                        className="form-control"
                        {...register("amount", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.amount?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                </>
              )}
              {orderType?.label === "Device License" ? (
                <>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{totalSitesLabel}</Label>
                    <Col>
                      <input
                        placeholder="No Of Sites"
                        type="number"
                        className="form-control"
                        {...register("no_of_sites", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sites?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{totalSensorsLabel}</Label>
                    <Col>
                      <input
                        placeholder="No Of Sensors"
                        type="number"
                        className="form-control"
                        {...register("no_of_sensors", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sensors?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                </>
              ) : orderType?.label === "Upgrade + Cloud Sync" ? (
                <>
                  {/* <FormGroup>
                    <Label sm={12}>{cloudSyncLabel}</Label>
                    {/* <Col>
                      <input
                        placeholder="Validity Date"
                        type="date"
                        className="form-control"
                        min={
                          deviceDetail?.cloud_sync_valid_up_to !== null
                            ? deviceDetail?.cloud_sync_valid_up_to
                            : new Date().toISOString().split("T")[0]
                        }
                        {...register("expiry_date", {
                          required: true,
                        })}
                      />
                      {errors.expiry_date?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col> */}
                  {/* </FormGroup> */}
                  <Label sm={12}>{cloudSyncLabel}</Label>
                  <StartdateEndDate
                    register={register}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                    startDate="cloud_sync_valid_up_from"
                    endDate="cloud_sync_valid_up_to"
                    startDateLabel="Start date"
                    endDateLabel="End date"
                    is_Required={true}
                    endDateHandler={endDateHandlerFunction}
                  />
                </>
              ) : orderType?.label === "Both" ? (
                <>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{totalSitesLabel}</Label>
                    <Col>
                      <input
                        placeholder="No Of Sites"
                        type="number"
                        className="form-control"
                        {...register("no_of_sites", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sites?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{totalSensorsLabel}</Label>
                    <Col>
                      <input
                        placeholder="No Of Sensors"
                        type="number"
                        className="form-control"
                        {...register("no_of_sensors", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sensors?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{cloudSyncLabel}</Label>
                    {/* <Col>
                      <input
                        placeholder="Validity Date"
                        type="date"
                        className="form-control"
                        min={
                          deviceDetail?.cloud_sync_valid_up_to !== null
                            ? deviceDetail?.cloud_sync_valid_up_to
                            : new Date().toISOString().split("T")[0]
                        }
                        {...register("expiry_date", {
                          required: true,
                        })}
                      />
                      {errors.expiry_date?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col> */}
                  </FormGroup>

                  <StartdateEndDate
                    register={register}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    startDate="cloud_sync_valid_up_from"
                    endDate="cloud_sync_valid_up_to"
                    startDateLabel="Start date"
                    endDateLabel="End date"
                    is_Required={true}
                    endDateHandler={endDateHandlerFunction}
                  
                  />
                </>
              ) : null}
            </>
          )}
        </div>

        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios || orderType === null}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modals>
  );
}

export default InitiateNewOrder;
