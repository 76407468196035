// _____for usermanagment_____
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    series : [],
    options: {}
};

const DashboardSlice = createSlice({
    name: 'dashboard_data',
    initialState,
    reducers:{
        addSeries(state,action) {
            state.series=action.payload
        },
        addOptions(state,action){
            state.options=action.payload
        },
        removeData(state,action){
            state.options={}
            state.series=[]
        }
       
    }
})

export const {addSeries , removeData,addOptions } = DashboardSlice.actions;
export default DashboardSlice.reducer;