import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import Modals from "../../../components/Modal";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../helpers/Alert";
import { useEffect } from "react";
import { industryList } from "../../../helpers/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setClientDetail } from "../../../store/client";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";

function EditClient(props) {
  const dispatch = useDispatch();
  const logoutHandler = useUnAuthorizedHandler();

  const customStyles = {
    menu: (base) => ({
      ...base,
      maxHeight: "150px", // your desired height
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px", // your desired height
    }),
  };

  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const clientDetail = useSelector((state) => state.ClientSlice.clientDetail);

  const [waitingForAxios, setwaitingForAxios] = useState(false);
  // const [timeZone] = useState(getTimeZone());
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const temp = { ...clientDetail };
    reset(temp);

    let indus_val = industryList.find(
      (data) => data.value === clientDetail.industry
    );
    if (indus_val) {
      setSelectedIndustry({
        value: clientDetail.industry,
        label: clientDetail.industry,
      });
    } else {
      setSelectedIndustry({
        value: "Other Industry",
        label: "Other Industry",
        name: "Other Industry",
      });
    }
    // setSelectedTimeZone({
    //   value: clientDetail.client_timezone,
    //   label: clientDetail.client_timezone,
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    setwaitingForAxios(true);
    let formData = new FormData();

    formData.append("authority_contact_email", data.authority_contact_email);
    if (selectedIndustry?.value === "Other Industry") {
      formData.append("industry", data.industry);
    } else {
      formData.append("industry", selectedIndustry?.value);
    }
    formData.append("name", data.name);

    if (data.address !== "") {
      formData.append("address", data.address);
    }

    let url = `/abacicount/clientrud/${clientDetail?.id}`;
    authAxios
      .patch(url, formData)
      .then((response) => {
        dispatch(setClientDetail(response.data));
        setwaitingForAxios(false);
        Toastr("success", "Client details updated successfully!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error, logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Client name *</Label>
          <Col>
            <input
              placeholder="Client name"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("name", {
                required: true,
              })}
            />
            {errors.name?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Email *</Label>
          <Col>
            <input
              placeholder="Email"
              type="email"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("authority_contact_email", {
                required: true,
              })}
            />
            {errors.authority_contact_email?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Address</Label>
          <Col>
            <textarea
              style={{ height: "100px", backgroundColor: "#FAFAFA" }}
              placeholder="Address"
              type="text"
              className="form-control"
              {...register("address", {})}
            />
          </Col>
        </FormGroup>

        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Industry *</Label>
          <Col>
            <Select
              placeholder="Select Industry"
              options={industryList}
              onChange={setSelectedIndustry}
              styles={customStyles}
              value={selectedIndustry}
            />
            {/* <select
              className="form-control"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              {...register("industry", {
                required: true,
              })}
            >
              <option value="">-- Select Industry --</option>
              {industryList?.map((data, idx) => (
                <option value={data.name} key={idx}>
                  {data.name}
                </option>
              ))}
            </select>
            {errors.industry?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )} */}
          </Col>
        </FormGroup>
        {selectedIndustry?.value === "Other Industry" && (
          <FormGroup style={{ marginBottom: "5px" }}>
            <Label sm={3}>Industry Name *</Label>
            <Col>
              <input
                placeholder="Industry Name"
                type="text"
                styles
                style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                className="form-control"
                {...register("industry", {
                  required: true,
                })}
              />
              {errors.industry?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
        )}

        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios || selectedIndustry === null}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
      {/* } */}
    </Modals>
  );
}

export default EditClient;
