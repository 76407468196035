import React, { useState, useRef } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import MaterialTable from "material-table";
import { APP_NAME } from "../../../helpers/Constants";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";
import { authAxios } from "../../../axiosInstance";
import { exportData } from "../../../helpers/fileDownload";
import AddUser from "./addUser";
import { CustomButton } from "../../../components/CustomButton";
import Swal from "sweetalert2";
import { DeleteOutline, Edit } from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import Unlock from "@material-ui/icons/LockOpen";
import EditUser from "./editUser";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";
import { useSelector } from "react-redux";

export default function Users() {

  const profile = useSelector((state) => state.authSlice.profile);
  const logoutHandler = useUnAuthorizedHandler();
  const tableRef = useRef();
  const totalRecordsCount = useRef(0);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [itemToBeEdited, setItemToBeEdited] = useState(null);
  const urlBackup = useRef("");
  const [filterEnabled] = useState(false);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "/dashboard" },
      { title: "Users", link: "#" },
    ],
  });

  const addModalToggle = () => {
    setAddModalShow(!addModalShow);
  };
  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };

  const columns = [
    {
      title: "Sl No",
      field: "sl_no",
    },
    {
      title: "First name",
      field: "first_name",
      render: (rowData) => (
        <>{rowData.first_name ? rowData.first_name : "----"}</>
      ),
    },
    {
      title: "Last name",
      field: "last_name",
      render: (rowData) => (
        <>{rowData.last_name ? rowData.last_name : "----"}</>
      ),
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "User Type",
      field: "user_type",
    },
    {
      title: "Status",
      field: "user_status",
    },
  ];

  const handleUpdateDelete = (actionType, row_id) => {
    const url = `/users_api/userrud/${row_id}`;

    let dataToBeSend, Message;

    switch (actionType) {
      case "Deleted":
        dataToBeSend = { user_status: "Deleted" };
        Message = "User Deleted Successfully!";
        break;
      case "Deactivated":
        dataToBeSend = { user_status: actionType };
        Message = "User Deactivated Successfully!";
        break;
      case "Activated":
        dataToBeSend = { user_status: actionType };
        Message = "User Activated Successfully!";
        break;
      default:
        break;
    }

    if (url && dataToBeSend && Message) {
      authAxios["patch"](url, dataToBeSend)
        .then((res) => {
          tableRef.current.onQueryChange();
          Toastr("success", Message);
        })
        .catch((err) => {
          Toastr("error", Error(err,logoutHandler));
        });
    }
  };

  return (
    <React.Fragment>
      {addModalShow && (
        <AddUser
          isOpen={addModalShow}
          toggle={addModalToggle}
          title="Add User"
          tableRef={tableRef}
        />
      )}
      {editModalShow && (
        <EditUser
          isOpen={editModalShow}
          toggle={editModalToggle}
          title="Edit User"
          tableRef={tableRef}
          item={itemToBeEdited}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Users"
            breadcrumbItems={dashboardState.breadcrumbItems}
          />
          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    title=" "
                    columns={columns}
                    tableRef={tableRef}
                    data={(query) => {
                      return new Promise((resolve, reject) => {
                        let orderBy = "";

                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }

                        let url = `/users_api/userlistcreate?limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;

                        urlBackup.current = url;

                        authAxios
                          .get(url)
                          .then((response) => {
                            totalRecordsCount.current = response.data?.count;
                            const tempList = response.data?.results.map(
                              (data, idx) => ({ ...data, sl_no: idx + 1 })
                            );

                            resolve({
                              data: tempList,
                              page: query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            Toastr('error',Error(error,logoutHandler))
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          });
                      });
                    }}
                    actions={[
                      (rowData) => ( {
                        icon: Edit,
                        tooltip: "Edit User",
                        onClick: (event, rowData) => {
                          setItemToBeEdited(rowData);
                          editModalToggle();
                        },
                        hidden:profile.user_type !== 'Admin' || rowData.id === profile.id
                      }),
                      (rowData) => ({
                        icon: DeleteOutline,
                        tooltip: "Delete User",
                        onClick: (event, rowData) => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Delete it!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handleUpdateDelete("Deleted", rowData.id);
                            }
                          });
                        },
                        hidden: profile.user_type !== 'Admin' || rowData.id === profile.id
                      }),

                      (rowData) => ({
                        icon: Unlock,
                        tooltip: "Activate User",
                        onClick: (event, rowData) => {
                          Swal.fire({
                            title: "Are you sure?",

                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Activate!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handleUpdateDelete("Activated", rowData.id);
                            }
                          });
                        },
                        hidden: rowData.user_status === "Activated" || profile.user_type !== 'Admin' || rowData.id === profile.id,
                      }),
                      (rowData) => ({
                        icon: LockIcon,
                        tooltip: "Deactivate User",
                        onClick: (event, rowData) => {
                          Swal.fire({
                            title: "Are you sure?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Deactivate!",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handleUpdateDelete("Deactivated", rowData.id);
                            }
                          });
                        },
                        hidden: rowData.user_status === "Deactivated" || profile.user_type !== 'Admin' || rowData.id === profile.id,
                      }),
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      exportButton: true,
                      headerStyle: {
                        zIndex: 0,
                      },
                      exportCsv: async () =>
                        exportData(
                          "csv_download",
                          urlBackup.current,
                          totalRecordsCount.current,
                          "Client List"
                        ),
                      exportPdf: async () =>
                        exportData(
                          "pdf_download",
                          urlBackup.current,
                          totalRecordsCount.current,
                          "Client List"
                        ),
                      debounceInterval: 500,
                      filtering: filterEnabled,
                    }}
                    icons={tableIcons}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          { profile.user_type === 'Admin' &&
          <CustomButton
            onClick={() => {
              addModalToggle();
            }}
            title={"+"}
          />
          }
        </Container>
      </div>
    </React.Fragment>
  );
}
