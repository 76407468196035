import axios from "axios";
import { baseURL } from "./helpers/baseURL";
import Cookies from "js-cookie";

export const publicAxios = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: { "content-type": "application/json", accept: "application/json" },
  withCredentials: true,
});

let token;
const authAxios = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "content-type": "application/json",
    accept: "application/json",
    Authorization: "Bearer " + token,
  },
  withCredentials: true,
});

const authAxiosFileUpload = axios.create({
  baseURL: baseURL,
  headers: {
    "content-type": "multipart/form-data",
    accept: "application/json",
    Authorization: "Bearer " + token,
  },
  withCredentials: true,
});

export const updateToken = (newToken) => {

  authAxios.defaults.headers.Authorization = "Bearer " + newToken;
  authAxiosFileUpload.defaults.headers.Authorization = "Bearer " + newToken;
};

export const setInitialToken = () => {
  
  token = Cookies.get("token");
  authAxios.defaults.headers.Authorization = "Bearer " + token;
  authAxiosFileUpload.defaults.headers.Authorization = "Bearer " + token;
  authAxios.defaults.baseURL = baseURL
  authAxiosFileUpload.defaults.baseURL = baseURL
};

setInitialToken();

export { authAxios, authAxiosFileUpload };
