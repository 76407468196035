
import moment from 'moment'

function Moments($date, $type = "") {
    if($type === "datetime"){
        return moment($date).format('DD-MM-YYYY hh:mm A')
    }
    else if($type === 'datetimeseconds')
    {
        return moment($date).format('DD-MM-YYYY hh:mm:ss A')  
    }
    else{
        return moment($date).format('DD-MM-YYYY')
    }
}



export default Moments