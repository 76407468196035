import React, { useState, useEffect } from "react";

import {
  Spinner,
  Col,
  Button,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
// import ImageCropper from "../../../components/ImageCropper/ImageCropper";
// import { setProfile } from "../../../store/auth";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../helpers/baseURL";

import "toastr/build/toastr.min.css";
import defaultImage from "../../../assets/images/default.png";
import Modals from "../../Modal";
import ImageCropper from "../../../helpers/ImageCropper";
import { SuccessAlert, Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
// import { authAxios } from "../../../axiosInstance";
import { setProfile } from "../../../store/auth";
import axios from "axios";
// import Error from "../../../helpers/Error";
// import { SuccessAlert, Toastr } from "../../../helpers/Alert";
// import Moments from "../../../helpers/Moments";

export default function ViewProfile(props) {
  const profile = useSelector((state) => state.authSlice.profile);
  const dispatch = useDispatch();
  const [editProfile, setEditProfile] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState();
  // useEffect(() => {
  //   if (profile) {
  //     // setLastName(profile && profile.last_name);
  //     setFirstName(profile && profile.first_name);
  //     if (profile.avatar !== null) {
  //       setProfileImage(baseURL + profile.avatar.substring(1));

  //     } else {
  //       setProfileImage(defaultImage)
  //     }
  //     setCroppedImage('');
  //   }

  // }, [profile, props.isOpen]);
  useEffect(() => {
    if (profile !== null) {
      if (profile.avatar !== null) {
        setProfileImage(baseURL + profile.avatar);
      } else {
        setProfileImage(defaultImage);
      }
      if (profile.last_name !== null) {
        setLastName(profile.last_name);
        setFirstName(profile.first_name);
      } else {
        setFirstName(profile.first_name);
      }
    }
  }, [profile]);
  const editProfileHandler = () => {
    setEditProfile(!editProfile);
  };
  const submitHandler = () => {
    setwaitingForAxios(true);

    let formData = new FormData(); //formdata object

    formData.append("first_name", firstName ? firstName : "");
    formData.append("last_name", lastName ? lastName : "");

    formData.append("avatar", croppedImage ? croppedImage : "");

    const url = "/users_api/profile";
    axios
      .post(url, formData)
      .then((response) => {
        dispatch(setProfile(response.data));
        setwaitingForAxios(false);
        props.toggle();
        SuccessAlert("Profile updated Successfully!");
      })
      .catch((error) => {
        setwaitingForAxios(false);

        const error_msg = Error(error);
        Toastr("error", error_msg);
      });
  };
  return (
    <div>
      <Modals
        isOpen={props.isOpen}
        toggle={props.toggle}
        title={props.title}
        size={props.size}
      >
        <div
          style={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <p>
            <img
              style={{
                display: "block",
                margin: "auto",
                width: "10rem",
                height: "10rem",
                borderRadius: "100%",
                padding: "3px",
                border: "2px solid #858b94",
              }}
              alt=""
              src={croppedImage ? croppedImage : profileImage}
            />
          </p>

          {editProfile ? (
            <div style={{ marginTop: "-10px" }}>
              <br />
              <form>
                <FormGroup row style={{ marginBottom: "10px" }}>
                  <Label for="exampleEmail2" sm={4}>
                    Profile Image
                  </Label>
                  <Col sm={8}>
                    <ImageCropper
                      disabled={waitingForAxios}
                      setCropppedImage={setCroppedImage}
                      hidden={true}
                      is_profile={true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "10px" }}>
                  <Label for="exampleEmail2" sm={4}>
                    First Name
                  </Label>
                  <Col sm={8}>
                    <input
                      disabled={waitingForAxios}
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      style={{ width: "100%" }}
                      className="form-control form-control-sm"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      // required
                      // {...register("first_name")}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row style={{ marginBottom: "10px" }}>
                  <Label for="exampleEmail2" sm={4}>
                    Last Name
                  </Label>
                  <Col sm={8}>
                    <input
                      disabled={waitingForAxios}
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      style={{ width: "100%" }}
                      className="form-control form-control-sm"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}

                      // {...register("last_name")}
                    />
                  </Col>
                </FormGroup>
              </form>
            </div>
          ) : (
            <div style={{ marginTop: "20px" }}>
              <table cellPadding={"10px"}>
                <tbody>
                  <tr>
                    <td> First Name</td>
                    <td>:</td>
                    <td>{profile?.first_name}</td>
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    <td>:</td>
                    <td>{profile?.last_name ? profile.last_name : "----"}</td>
                  </tr>
                  <tr>
                    <td>Email </td>
                    <td>:</td>
                    <td>{profile.email}</td>
                  </tr>
                </tbody>
              </table>

              {/* <p>
            {profile?.first_name} {profile?.last_name}
          </p>
          <p> {profile?.email}</p> */}
              {/* <div className="text-center" style={{display:"flex",justifyContent:"center"}}>
          <table>
            <tbody>
              <tr>
                <td>Last Logged</td>
              <td> : </td>
              <td>{profile? Moments(profile.last_login_date,"datetime") : "Unknown"}</td>
              </tr>
            </tbody>

          </table>
         </div> */}
            </div>
          )}
        </div>

        <ModalFooter>
          <Button
            type="submit"
            color={editProfile ? "danger" : "secondary"}
            className="w-xs waves-effect waves-light me-1"
            onClick={editProfileHandler}
          >
            {editProfile ? "Return" : "Edit Profile"}
          </Button>
          {editProfile ? (
            <Button
              type="button"
              color="secondary"
              className="w-xs waves-effect waves-light me-1"
              onClick={submitHandler}
              disabled={waitingForAxios}
            >
              {waitingForAxios ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                "Save"
              )}
            </Button>
          ) : null}
        </ModalFooter>
      </Modals>
    </div>
  );
}
