import React, { useState, useEffect } from "react";

// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import {

// } from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";
import { useSelector, useDispatch } from "react-redux";
import { 
  changeSidebarType
   } from "../../store/layout";
  // import {   changeLayout,
  //   changeSidebarTheme,
  //   changeSidebarType,
  //   toggleRightSidebar,
  //   changeTopbarTheme,
  //   changeLayoutWidth } from "../../store/layout";
import { useLocation } from 'react-router-dom'
import { APP_NAME } from "../../helpers/Constants";

export default function Layout(props) {
  const [isMobile] = useState(false);
  const layout = useSelector((state) => state.layoutSlice);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(()=>{
    // const width = 100
    // changeBodyAttribute("data-layout-size", 'boxed')


    window.scrollTo(0, 0);
    let currentage = capitalizeFirstLetter(location.pathname);
    document.title =
      currentage + " | " + APP_NAME;
      // currentage + " | Smart Grease Trap Management System";

  },[location])
  const toggleMenuCallback = () => {
    // console.log(layout.leftSideBarType)
    if (layout.leftSideBarType === "default") {
      changeLeftSidebarType("condensed", isMobile);
      dispatch(changeSidebarType("condensed"))
    } else if (layout.leftSideBarType === "condensed") {
      changeLeftSidebarType("default", isMobile);
      dispatch(changeSidebarType("default"))
    }
  };
  const toggleRightSidebar = () => {
    dispatch(toggleRightSidebar());
  };
  const capitalizeFirstLetter = str => (
    str.charAt(1).toUpperCase() + str.slice(2)
  )

  const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
  }
  const manageBodyClass = (cssClass, action = "toggle") => {
    // console.log(">>>>>>>>>>>>>>>>>>>>>>",cssClass);
    switch (action) {
      case "add":
        if (document.body) document.body.classList.add(cssClass);
        break;
      case "remove":
        if (document.body) document.body.classList.remove(cssClass);
        break;
      default:
        if (document.body) document.body.classList.toggle(cssClass);
        break;
    }
  
    return true;
  }

  const changeLeftSidebarType = (sidebarType, isMobile) => {
    try {
      switch (sidebarType) {
        case "compact":
          changeBodyAttribute("data-sidebar-size", "small");
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("vertical-collpsed", "remove");
          break;
        case "icon":
          changeBodyAttribute("data-keep-enlarged", "true");
          manageBodyClass("vertical-collpsed", "add");
          break;
        case "condensed":
          manageBodyClass("sidebar-enable", "add");
          if (!isMobile) manageBodyClass("vertical-collpsed", "add");
          break;
        default:
          changeBodyAttribute("data-sidebar-size", "");
          manageBodyClass("sidebar-enable", "remove");
          if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
          break;
      }
    } catch (error) { }
  }

  return (
    <>
      {/* <div id='preloader'>
        <div id='status'>
          <div className='spinner'>
            <i className='ri-loader-line spin-icon'></i>
          </div>
        </div>
      </div> */}
      <div id="layout-wrapper">
      <Header toggleMenu={toggleMenuCallback} toggleRightSidebar={toggleRightSidebar} />
      <Sidebar
          theme={"dark"}
          type={"default"}
          isMobile={false}
        />
   <div className="main-content">
                {props.children}
                <Footer/>
              </div>
      <Footer/>
      </div>
      <Rightbar />
    </>
  );
}

