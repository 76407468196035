import React from 'react';
import {  Container } from 'reactstrap';



function Index() {

//   const [finishStatus, _setfinishStatus] = useState(false);

// const finishStatusRef = React.useRef(finishStatus);
// const setfinishStatus = data => {
//   finishStatusRef.current = data;
//   _setfinishStatus(data);
// };

// const onBackButtonEvent = (e) => {
//     e.preventDefault();
//     if (!finishStatusRef.current) {
//         if (window.confirm("Do you want to go back ?")) {
//             setfinishStatus(true)
//             // your logic
//             window.history.push("/");
//         } else {
//             window.history.pushState(null, null, window.location.pathname);
//             setfinishStatus(false)
//         }
//     }
// }

// const _backConfirm = async () => {
//   let event = window.confirm("Changes that you may not be saved.");
//   if(event){
//       window.history.pushState(null, "", window.location.href);
//   }
// }







//   const [state,setState]=useState(
//     {
//       latitude: null,
//       longitude: null,
//     }
//   )
//  const position = async () => {
//     await navigator.geolocation.getCurrentPosition(
//       position => console.log(position,'position'),
//       err => console.log(err)
//     );
  
//   }

  return (
    <React.Fragment>
   
    
  
            <div className="page-content">
              <Container fluid>
              <button >Filter</button>

<div className='d-flex justify-content-center' style={{gap:'10px'}}>
              <div  style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'0% 0%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'20% 0%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'40% 0%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'60% 0%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'80% 0%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'100% 0%'}}/>

              </div>
              </div>
              
<div className='d-flex justify-content-center' style={{gap:'10px',marginTop:'10px'}}>
              <div  style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'0% 65%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'20% 65%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'40% 65%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'60% 65%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'80% 65%'}}/>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'none',objectPosition:'100% 65%'}}/>

              </div>
              </div>


{/*               
<div className='d-flex justify-content-center' style={{gap:'10px'}}>
              <div  style={{width:'150px',height:'75px',overflow:'hidden'}}>
              <video   autoPlay
                loop muted height='100%' width='100%' style={{objectFit:'none',objectPosition:'0% 0%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4"  />
                    </video>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
              <video   autoPlay
                loop muted  height='100%' width='100%' style={{objectFit:'none',objectPosition:'20% 0%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                    </video>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
              <video   autoPlay
                loop muted height='100%' width='100%' style={{objectFit:'none',objectPosition:'40% 0%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4"  />
                    </video>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
              <video   autoPlay
                loop muted  height='100%' width='100%' style={{objectFit:'none',objectPosition:'60% 0%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                    </video>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
              <video   autoPlay
                loop muted height='100%' width='100%' style={{objectFit:'none',objectPosition:'80% 0%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4"  />
                    </video>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
              <video   autoPlay
                loop muted height='100%' width='100%' style={{objectFit:'none',objectPosition:'100% 0%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4"  />
                    </video>

              </div>
              </div>
              
<div className='d-flex justify-content-center' style={{gap:'10px',marginTop:'10px'}}>
              <div  style={{width:'150px',height:'75px',overflow:'hidden'}}>
              <video   autoPlay
                loop muted  height='100%' width='100%' style={{objectFit:'none',objectPosition:'0% 65%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                </video>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
              <video   autoPlay
                loop muted   height='100%' width='100%' style={{objectFit:'none',objectPosition:'20% 65%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                    </video>

              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                 <video   autoPlay
                loop muted  height='100%' width='100%' style={{objectFit:'none',objectPosition:'40% 65%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4"  />
</video>
              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                 <video   autoPlay
                loop muted height='100%' width='100%' style={{objectFit:'none',objectPosition:'60% 65%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4"  />
</video>
              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                 <video   autoPlay
                loop muted height='100%' width='100%' style={{objectFit:'none',objectPosition:'80% 65%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4"  />
</video>
              </div>
              <div className='' style={{width:'150px',height:'75px',overflow:'hidden'}}>
                 <video   autoPlay
                loop muted  height='100%' width='100%' style={{objectFit:'none',objectPosition:'100% 65%'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" /></video>

              </div>
              </div> */}
              <div className='d-flex justify-content-center' style={{width:'550px',height:'550px',overflow:'hidden'}}>
                <img src="https://picsum.photos/900/180" alt='' height='100%' width='100%' style={{objectFit:'contain'}}/>

              </div>

              <div className='d-flex justify-content-center' style={{width:'400px',height:'400px',overflow:'hidden'}}>
                 <video   autoPlay
                loop muted  height='100%' width='100%' style={{objectFit:'contain'}}>
                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" /></video>

              </div>
                    </Container>
                    </div>
                    </React.Fragment>
  )
}

export default Index;

