import React from "react";
import SimpleBar from "simplebar-react";
import SidebarContent from "./SidebarContent";


export default function Sidebar(props) {
    return (
        <React.Fragment>
            <div className="vertical-menu" style={{ zIndex: "100" }}>
                <div data-simplebar className="h-100">
                    {
                        props.type !== "condensed" ?
                            (
                                <SimpleBar style={{ maxHeight: "100%" }}>
                                    <SidebarContent />
                                </SimpleBar>
                            ) :
                            <SidebarContent />
                    }
                </div>

            </div>
        </React.Fragment>
    );
}