import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Modals from "../../../components/Modal";
import { authAxios } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../helpers/Alert";
import { numberInvalidCharacterPreventer } from "../../../helpers/functions";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setClientDetail } from "../../../store/client";
import Select from "react-select";

function AddDevice(props) {

  const customStyles = {
    menu: (base) => ({
      ...base,
      maxHeight: "150px", // your desired height
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px", // your desired height
    }),
  };
  const dispatch = useDispatch();
  const clientDetail = useSelector((state) => state.ClientSlice.clientDetail);
  const logoutHandler = useUnAuthorizedHandler();
  const [timeZoneArray, setTimeZoneArray] = useState(null);
  const [loading,setIsLoading] = useState(true)
  const [selectedTimeZone,setSelectedTimeZone] = useState(null);
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

    useEffect(() => {
    setIsLoading(true);
    let temp = [];
    authAxios
      .get("/abacicount/timezone_list")
      .then((res) => {
        res.data.forEach((data) => {
          temp.push({ label: data, value: data });
        });
        setTimeZoneArray(temp);
        setIsLoading(false);
      })
      .catch((err) => {
        Toastr("error", Error(err, logoutHandler));
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onSubmit = async (data) => {

    setwaitingForAxios(true);
    let formData = new FormData();
    formData.append('client',id)
    formData.append("device_id", data.device_id);
    formData.append("amount", data.amount);
    formData.append("device_timezone", selectedTimeZone?.value);

    if (data.no_of_sensors !== "") {
      formData.append("no_of_sensors", data.no_of_sensors);
    }
    if (data.no_of_sites !== "") {
      formData.append("no_of_sites", data.no_of_sites);
    }

    let url = "/abacicount/clientdevice";
    authAxios
      .post(url, formData)
      .then((response) => {
        // dispatch(addNewClients(response.data))
        props.tableRef.current.onQueryChange();
        setwaitingForAxios(false);

        let dataForRedux = {...clientDetail,
                            total_no_of_sites:Number(clientDetail.total_no_of_sites)+Number(data.no_of_sites),
                            total_no_of_sensors:Number(clientDetail.total_no_of_sensors)+Number(data.no_of_sensors)
                            }

        dispatch(setClientDetail(dataForRedux))
        props.setRevenue((previousRevenue) => Number(previousRevenue)+Number(data.amount))
        Toastr("success", "New device successfully created!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error,logoutHandler);
        ErrorAlert(error_msg);
      });
  };


  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Device id</Label>
          <Col>
            <input
              placeholder="Device id"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                numberInvalidCharacterPreventer(e);
              }}
              {...register("device_id", {
                required: true,
              })}
            />
            {errors.device_id?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Device timezone</Label>
          <Col>
            <Select
              placeholder="Select TimeZone"
              options={timeZoneArray}
              onChange={setSelectedTimeZone}
              styles={customStyles}
              value={selectedTimeZone}
              isLoading={loading}
            />
          </Col>
        </FormGroup>
       
        <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>No Of Sites</Label>
                    <Col>
                      <input
                        placeholder="No Of Sites"
                        type="number"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("no_of_sites", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sites?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>No Of Sensors</Label>
                    <Col>
                      <input
                        placeholder="No Of Sensors"
                        type="number"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("no_of_sensors", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sensors?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                  <Label sm={12}>Amount</Label>
                  <Col>
                    <input
                      placeholder="Amount"
                      type="number"
                      style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                      className="form-control"
                      {...register("amount", {
                        required: true,
                      })}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {
                        numberInvalidCharacterPreventer(e);
                      }}
                    />
                    {errors.amount?.type === "required" && (
                      <span style={{ color: "red" }}>
                        *This field is required
                      </span>
                    )}
                  </Col>
                </FormGroup>
               
        <ModalFooter>
          <Button
            type="submit"
            disabled={
              waitingForAxios 
            }
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
      {/* } */}
    </Modals>
  );
}

export default AddDevice;
