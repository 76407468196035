import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Modals from "../../../components/Modal";
import { authAxiosFileUpload } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../helpers/Alert";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";

function AddVersion(props) {
  const logoutHandler = useUnAuthorizedHandler();

  const [waitingForAxios, setwaitingForAxios] = useState(false);
  // const [confirmPassword, setConfirmPassword] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setwaitingForAxios(true);
    let formData = new FormData();

    // formData.append("country", data.country);
    formData.append("version", data.version);

    let url = "/abacicount/version";
    authAxiosFileUpload
      .post(url, formData)
      .then((response) => {
        // dispatch(addNewClients(response.data))
        props.tableRef.current.onQueryChange();
        setwaitingForAxios(false);
        Toastr("success", "New version added successfully!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error,logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Version Code</Label>
          <Col>
            <input
              placeholder="Version Code"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("version", {
                required: true,
              })}
            />
            {errors.version?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
      {/* } */}
    </Modals>
  );
}

export default AddVersion;
