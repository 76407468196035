import React, { useState } from "react";
import { ReactComponent as SiteLogo } from "../../assets/icons/Site.svg";
import { ReactComponent as SensorLogo } from "../../assets/icons/Sensor.svg";
import IconButton from "@mui/material/IconButton";
import KeyIcon from "@mui/icons-material/Key";
import EditIcon from "@mui/icons-material/Edit";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { Tooltip } from "@material-ui/core";
import EditClient from "../../containers/Pages/Clients/editClient";
import { useDispatch, useSelector } from "react-redux";
import Moments from "../../helpers/Moment";
import { authAxios } from "../../axiosInstance";
import Swal from "sweetalert2";
import { setClientDetail } from "../../store/client";
import Error from "../../helpers/Error";
import { Toastr } from "../../helpers/Alert";
import InitiateNewOrder from "./InitiateNewOrder";
import useUnAuthorizedHandler from "../../hooks/useUnAuthorizedHandler";
import GenerateToken from "../../containers/Pages/Clients/generateToken";

function LeftDetailComponent({ tableRef }) {
  const logoutHandler = useUnAuthorizedHandler();

  // const { client_id } = useParams();
  const profile = useSelector((state) => state.authSlice.profile);
  const clientDetail = useSelector((state) => state.ClientSlice.clientDetail);
  const dispatch = useDispatch();
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [isModalShow, setisModalShow] = useState(false);
  const updateModalToggle = () => {
    setUpdateModalShow(!updateModalShow);
  };
  const editModalToggle = () => {
    setEditModalShow(!editModalShow);
  };
  const generateModalToggle = () => {
    setisModalShow(!isModalShow);
  };
  const deactivateHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `/abacicount/clientrud/${clientDetail?.id}`;
        authAxios
          .delete(url)
          .then((res) => {
            dispatch(
              setClientDetail({ ...clientDetail, status: "Deactivated" })
            );
            tableRef.current.onQueryChange({ page: 0 });
          })
          .catch((err) => {
            let err_msg = Error(err, logoutHandler);
            Toastr("error", err_msg);
          });
      }
    });
  };

  const swalAlertHandler = () => {};

  return (
    <>
      {updateModalShow && (
        <InitiateNewOrder
          isOpen={updateModalShow}
          toggle={updateModalToggle}
          title={"Initiate New Order"}
          tableRef={tableRef}
        />
      )}
      {editModalShow && (
        <EditClient
          isOpen={editModalShow}
          toggle={editModalToggle}
          title={"Edit Client"}
        />
      )}

      {isModalShow && (
        <GenerateToken
          isOpen={isModalShow}
          toggle={generateModalToggle}
          title={"Genetate Token"}
          size={"md"}
        />
      )}

      <div
        className="d-flex justify-content-center gap-5"
        style={{ marginBottom: "-20px" }}
      >
        <div className="d-flex flex-column gap-1">
          <SiteLogo />
          <p className="text-center">
            {clientDetail?.total_no_of_sites
              ? clientDetail.total_no_of_sites
              : 0}
          </p>
        </div>
        <div className="d-flex flex-column gap-1">
          <SensorLogo />
          <p className="text-center">
            {clientDetail?.total_no_of_sensors
              ? clientDetail?.total_no_of_sensors
              : 0}
          </p>
        </div>
      </div>
      <hr />
      <table
        cellSpacing="0"
        cellPadding="6"
        style={{ width: "100%", marginBottom: "40px" }}
      >
        <tbody>
          <tr className="text-muted">
            <td>Name : {clientDetail?.name}</td>
          </tr>
          <tr className="text-muted">
            <td>Email : {clientDetail?.authority_contact_email}</td>
          </tr>
          <tr className="text-muted">
            <td>Address : {clientDetail?.address}</td>
          </tr>

          <tr className="text-muted">
            <td>
              Industry : {clientDetail?.industry ? clientDetail.industry : "-"}
            </td>
          </tr>

          <tr className="text-muted">
            <td>
              Created by :{" "}
              {clientDetail?.created_by?.full_name
                ? clientDetail.created_by?.full_name
                : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Created Date :{" "}
              {clientDetail?.created_date
                ? Moments(clientDetail.created_date, "datetime")
                : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Modified by :{" "}
              {clientDetail?.modified_by?.full_name
                ? clientDetail.modified_by.full_name
                : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Modified Date :{" "}
              {clientDetail?.modified_date
                ? Moments(clientDetail.modified_date, "datetime")
                : "----"}
            </td>
          </tr>
          <tr className="text-muted">
            <td>
              Account Status :
              <span
                style={{ marginLeft: "5px" }}
                className={
                  clientDetail?.status === "Active"
                    ? "badge badge-soft-success"
                    : "badge badge-soft-danger "
                }
              >
                {clientDetail?.status}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className=""
        style={{
          visibility: clientDetail?.status === "Deactivated" && "hidden",
          marginTop: "135px",
        }}
      >
        <div className="d-flex justify-content-center gap-3">
          <Tooltip title="Edit Client">
            <IconButton
              onClick={editModalToggle}
              disabled={profile.user_type !== "Admin"}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Deactivate Client"
            onClick={deactivateHandler}
            disabled={profile.user_type !== "Admin"}
          >
            <IconButton>
              <PersonOffIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title="Generate Token"
            onClick={generateModalToggle}
            disabled={profile.user_type !== "Admin"}
          >
            <IconButton>
              <KeyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </>
  );
}

export default LeftDetailComponent;
