import React, { useState, useRef } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import MaterialTable from "material-table";
import { APP_NAME } from "../../../helpers/Constants";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { tableIcons } from "../../../helpers/MaterialIcons";
import AddClient from "./addClient";
import { CustomButton } from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../axiosInstance";
import { exportData } from "../../../helpers/fileDownload";
import Moments from "../../../helpers/Moment";
import { Toastr } from "../../../helpers/Alert";
import Error from "../../../helpers/Error";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";
import { useSelector } from "react-redux";

export default function Clients() {
  const profile = useSelector((state) => state.authSlice.profile);
  const logoutHandler = useUnAuthorizedHandler();
  const urlBackup = useRef("");
  const tableRef = useRef();
  const navigate = useNavigate();
  const totalRecordsCount = useRef(0);
  const [filterEnabled] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [dashboardState] = useState({
    breadcrumbItems: [
      { title: APP_NAME, link: "#" },
      { title: "Clients", link: "#" },
    ],
  });

  const addModalToggle = () => {
    setAddModalShow(!addModalShow);
  };

  const columns = [
    {
      title: "Created Date",
      field: "created_date",
      render: (rowData) => (
        <>
          {rowData.created_date
            ? Moments(rowData.created_date, "datetime")
            : "----"}
        </>
      ),
    },
    // {
    //   title: "Cloud Access No",
    //   field: "cloud_access_unique_number",
    // },
    // {
    //   title: "Device ID",
    //   field: "device_id",
    //   render: (rowData) => (
    //     <>{rowData.device_id ? rowData.device_id : "----"}</>
    //   ),
    // },
    {
      title: "Client Name",
      field: "name",
      // render: (rowData) => (
      //   <>
      //     <Tooltip arrow title={`View Details of ${rowData.name}`}>
      //       <Link
      //         to={`/clientdetails/${rowData.id}`}
      //         state={{client_name:rowData.name}}
      //         className="link_tag"
      //       >
      //         {rowData.name ? rowData.name : "----"}
      //       </Link>
      //     </Tooltip>
      //   </>
      // ),
    },
    {
      title: "Email",
      field: "authority_contact_email",
      render: (rowData) => (
        <>
          {rowData.authority_contact_email
            ? rowData.authority_contact_email
            : "----"}
        </>
      ),
    },

    {
      title: "Address",
      field: "address",
      render: (rowData) => <>{rowData.address ? rowData.address : "----"}</>,
    },
    // {
    //   title: "No of sites",
    //   field: "total_no_of_sites",
    //   render: (rowData) => (
    //     <>{rowData.total_no_of_sites ? rowData.total_no_of_sites : "----"}</>
    //   ),
    // },
    // {
    //   title: "No of sensors",
    //   field: "total_no_of_sensors",
    //   render: (rowData) => (
    //     <>
    //       {rowData.total_no_of_sensors ? rowData.total_no_of_sensors : "----"}
    //     </>
    //   ),
    // },
    {
      title: "Industry",
      field: "industry",
      render: (rowData) => <>{rowData.industry ? rowData.industry : "----"}</>,
    },
    { title: "Status", field: "status" },
    // {
    //   title: "Cloud Connection Status",
    //   field: "online_status",
    //   render: (rowData) => (
    //     <span
    //       className={
    //         rowData.online_status
    //           ? "badge badge-soft-success"
    //           : "badge badge-soft-danger"
    //       }
    //     >
    //       {rowData.online_status ? "Online" : "Offline"}
    //     </span>
    //   ),
    // },
  ];

  // const handleUpdateDelete = (actionType,row_id) => {
  //   const url =`/customers_api/tenantrud/${row_id}`

  //   let dataToBeSend, method, Message , reduxFunction,id;

  //   switch (actionType) {
  //     case 'delete':
  //       dataToBeSend = { status: 'Deleted' };
  //       method = 'patch';
  //       id=row_id
  //       Message = 'User Deleted Successfully!';
  //       reduxFunction = deleteClients
  //       break;
  //     case 'disable':
  //       dataToBeSend = { status: 'Blocked' };
  //       method = 'patch';
  //       Message = 'User Blocked Successfully!';
  //       reduxFunction = updateClients
  //       break;
  //     case 'enable':
  //       dataToBeSend = { status: 'Active' };
  //       method = 'patch';
  //       Message = 'User Activated Successfully!';
  //       reduxFunction = updateClients
  //       break;
  //     default:
  //       break;
  //   }

  //   if (url && dataToBeSend && method && Message) {
  //     // handleFetchUpdateDelete(url, dataToBeSend, reduxFunction, method, Message, id);
  //   }
  // }

  return (
    <React.Fragment>
      {addModalShow && (
        <AddClient
          isOpen={addModalShow}
          toggle={addModalToggle}
          title={"Add Client"}
          tableRef={tableRef}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Clients"
            breadcrumbItems={dashboardState.breadcrumbItems}
          />
          <Col xl={24}>
            <Card style={{ minHeight: "525px" }}>
              <CardBody>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <MaterialTable
                    title=" "
                    columns={columns}
                    tableRef={tableRef}
                    onRowClick={(e, rowData) =>
                      navigate(`/clientdetails/${rowData.id}`, {
                        state: { client_name: rowData.name },
                      })
                    }
                    data={(query) => {
                      return new Promise((resolve, reject) => {
                        let orderBy = "";

                        if (query.orderBy) {
                          orderBy =
                            query.orderDirection === "asc"
                              ? `&ordering=-${query.orderBy?.field}`
                              : `&ordering=${query.orderBy?.field}`;
                        }

                        let url = `/abacicount/clientlistcreate?limit=${
                          query.pageSize
                        }&offset=${query.pageSize * query.page}&search=${
                          query.search
                        }${orderBy}`;

                        urlBackup.current = url;

                        authAxios
                          .get(url)
                          .then((response) => {
                            totalRecordsCount.current = response.data?.count;
                            const tempList = response.data?.results;

                            resolve({
                              data: tempList,
                              page: query.page,
                              totalCount: response.data?.count,
                            });
                          })
                          .catch((error) => {
                            Toastr("error", Error(error, logoutHandler));
                            reject({
                              data: [],
                              page: query.page,
                              totalCount: 0,
                            });
                          });
                      });
                    }}
                    options={{
                      exportFileName: "Users List",
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportButton: true,
                      exportCsv: async () =>
                        exportData(
                          "csv_download",
                          urlBackup.current,
                          totalRecordsCount.current,
                          "Client List"
                        ),
                      exportPdf: async () =>
                        exportData(
                          "pdf_download",
                          urlBackup.current,
                          totalRecordsCount.current,
                          "Client List"
                        ),
                      debounceInterval: 500,
                      filtering: filterEnabled,
                    }}
                    icons={tableIcons}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {profile.user_type === "Admin" && (
            <CustomButton
              onClick={() => {
                addModalToggle();
              }}
              title={"+"}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
