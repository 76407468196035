import axios from "axios";
import { Toastr } from "./Alert";

export const getTimeZone = () => {

    let tempArray=[];

    var aryIanaTimeZones = Intl.supportedValuesOf('timeZone');

    aryIanaTimeZones.forEach((timeZone) => {tempArray.push(timeZone)});

    return tempArray;
}

export const timeZoneArray = async () => {
  try {
    const response = await axios.get("/count/timezone_list");
    const data = response.data;

    let timeZones = await data.map((item) => ({ label: item, value: item }));

    return timeZones;
  } catch (error) {
    Toastr("error", "Couldn't fetch time zones");
    return [];
  }
};
// export const getTimeZone = () => {

//   let tempArray=[];

//   var aryIanaTimeZones = Intl.supportedValuesOf('timeZone');

//   aryIanaTimeZones.forEach((timeZone) => {tempArray.push({label:timeZone,value:timeZone})});

//   return tempArray;
// }

export const numberInvalidCharacterPreventer = (e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()

export const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
     textArea.value=text; 
     document.body.appendChild(textArea); 
     textArea.select(); 
     try{document.execCommand('copy')}
     catch(err){console.error('Unable to copy to clipboard',err)}
     document.body.removeChild(textArea)
   };
   
export const isTimeInRange = (startTime, endTime, rangeStart, rangeEnd) => {
    const [startHour, startMinute] = startTime.split(":");
    const [endHour, endMinute] = endTime.split(":");
    const [rangeStartHour, rangeStartMinute] = rangeStart.split(":");
    const [rangeEndHour, rangeEndMinute] = rangeEnd.split(":");
  
    const startTimeInMinutes =
      parseInt(startHour, 10) * 60 + parseInt(startMinute, 10);
    const endTimeInMinutes = parseInt(endHour, 10) * 60 + parseInt(endMinute, 10);
    const rangeStartInMinutes =
      parseInt(rangeStartHour, 10) * 60 + parseInt(rangeStartMinute, 10);
    const rangeEndInMinutes =
      parseInt(rangeEndHour, 10) * 60 + parseInt(rangeEndMinute, 10);
  
    return (
      startTimeInMinutes >= rangeStartInMinutes &&
      endTimeInMinutes <= rangeEndInMinutes
    );
  };
  
  export const isValidTimeSelection = (selectedDay, startTime, endTime) => {
    // Convert selectedDay, startTime, and endTime to Date objects
    const selectedDayStart = new Date(selectedDay);
    const selectedDayEnd = new Date(selectedDay);
    const selectedStartTime = new Date(startTime);
    const selectedEndTime = new Date(endTime);
  
    // Set the time for the start and end of the selected day (00:00 and 23:59)
    selectedDayStart.setHours(0, 0, 0, 0);
    selectedDayEnd.setHours(23, 59, 59, 999);
  
    // Check if the start time is greater than the end time
    if (selectedStartTime >= selectedEndTime) {
      return false;
    }
  
    // Check if the start and end times are within the selected day's 24-hour range
    if (
      selectedStartTime >= selectedDayStart &&
      selectedEndTime <= selectedDayEnd
    ) {
      return true;
    }
  
    return false;
  };

  // Function to check if last datetime is not less than first datetime
  export const isLastDateTimeValid = (firstDateTime, lastDateTime) => {
    if (firstDateTime && lastDateTime) {
      const firstTimeInMinutes = convertTimeToMinutes(firstDateTime);
      const lastTimeInMinutes = convertTimeToMinutes(lastDateTime);
      const firstDate = new Date(firstDateTime);
      const lastDate = new Date(lastDateTime);
  
      if (lastDate.getTime() > firstDate.getTime()) {
        return true;
      } else if (lastDate.getTime() === firstDate.getTime()) {
        return lastTimeInMinutes >= firstTimeInMinutes;
      } else {
        return false;
      }
    }
    return false;
  };
  
  // Helper function to convert datetime to minutes
  export const convertTimeToMinutes = (datetime) => {
    const [datePart, timePart] = datetime.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);
    const [year, month, day] = datePart.split("-").map(Number);
  
    const date = new Date(year, month - 1, day, hours, minutes);
    return date.getTime() / (1000 * 60);
  };
  
