import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  loggedIn: null,
  profile: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin(state, action) {
      state.loggedIn = true;
      state.profile = action.payload.user;
    },
    setLogOut(state) {
      state.loggedIn = false;
      state.profile = null;
      Cookies.remove("token");
      localStorage.removeItem("token");
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
  },
});

export const { setLogin, setLogOut, setProfile } = authSlice.actions;
export default authSlice.reducer;
