const baseURLFunc = () => {
  const url = window.location.origin.split(":3001")[0];
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return url + ":8000";
  } else {
    return url;
  }
};

export const baseURL = baseURLFunc();
