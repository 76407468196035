import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Select from "react-select";

import Modals from "../../../components/Modal";
import { authAxios, authAxiosFileUpload } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../helpers/Alert";
import { industryList } from "../../../helpers/Constants";
import { numberInvalidCharacterPreventer } from "../../../helpers/functions";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";

function AddClient(props) {
  const logoutHandler = useUnAuthorizedHandler();
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  // const [timeZoneArray, setTimeZoneArray] = useState(null);
  const customStyles = {
    menu: (base) => ({
      ...base,
      maxHeight: "150px", // your desired height
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px", // your desired height
    }),
  };
  // const [isCloudChecked, setIsCloudChecked] = useState(false);
  // const [timeZone] = useState(getTimeZone());
  const {
    register,
    handleSubmit,
    // setValue,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   setIsLoading(true);
  //   let temp = [];
  //   authAxios
  //     .get("/abacicount/timezone_list")
  //     .then((res) => {
  //       res.data.forEach((data) => {
  //         temp.push({ label: data, value: data });
  //       });
  //       setTimeZoneArray(temp);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       Toastr("error", Error(err, logoutHandler));
  //       setIsLoading(false);
  //     });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onSubmit = async (data) => {
    setwaitingForAxios(true);
    let formData = new FormData();

    // formData.append("country", data.country);
    if (selectedIndustry?.value === "Other Industry") {
      formData.append("industry", data.industry);
    } else {
      formData.append("industry", selectedIndustry?.value);
    }
    formData.append("name", data.name);
    formData.append("device_id", data.device_id);
    formData.append("authority_contact_email", data.authority_contact_email);
    // formData.append("client_timezone", selectedTimeZone?.value);
    // formData.append("invoice_amount", data.invoice_amount);
    // formData.append("cloud_sync_activated", isCloudChecked);

    // if (data.no_of_sensors !== "") {
    //   formData.append("no_of_sensors", data.no_of_sensors);
    // }
    // if (data.no_of_sites !== "") {
    //   formData.append("no_of_sites", data.no_of_sites);
    // }
    if (data.address !== "") {
      formData.append("address", data.address);
    }
    // if (isCloudChecked) {
    //   formData.append("cloud_sync_valid_up_to", validUptoDate);
    // }
    // if (data.invoice_no !== "") {
    //   formData.append("invoice_number", data.invoice_no);
    // }
    // if (data.invoice_file.length !== 0) {
    //   console.log(data.invoice_file);
    //   formData.append("invoice_file", data.invoice_file[0]);
    // }

    let url = "/abacicount/clientlistcreate";
    authAxiosFileUpload
      .post(url, formData)
      .then((response) => {
        // dispatch(addNewClients(response.data))
        props.tableRef.current.onQueryChange();
        setwaitingForAxios(false);
        Toastr("success", "New client successfully created!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error, logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  // const changeHandler = (value) => {
  //   if (value) {
  //     const currentDate = new Date();
  //     const new_year = moment(currentDate).add(value, "y").format("YYYY-MM-DD");
  //     setValidUptoDate(new_year);
  //   } else {
  //     setValidUptoDate(null);
  //   }
  // };

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Client name *</Label>
          <Col>
            <input
              placeholder="Client name"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("name", {
                required: true,
              })}
            />
            {errors.name?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Email *</Label>
          <Col>
            <input
              placeholder="Email"
              type="email"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("authority_contact_email", {
                required: true,
              })}
            />
            {errors.authority_contact_email?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Address</Label>
          <Col>
            <textarea
              style={{ height: "100px", backgroundColor: "#FAFAFA" }}
              placeholder="Address"
              type="text"
              className="form-control"
              {...register("address", {})}
            />
          </Col>
        </FormGroup>

        {/* <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Device id</Label>
          <Col>
            <input
              placeholder="Device id"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                numberInvalidCharacterPreventer(e);
              }}
              {...register("device_id", {
                required: true,
              })}
            />
            {errors.device_id?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup> */}
        {/* <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>No of sites</Label>
          <Col>
            <input
              placeholder="No of sites"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                numberInvalidCharacterPreventer(e);
              }}
              {...register("no_of_sites", {
                // required: true,
              })}
            />

          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>No of sensor</Label>
          <Col>
            <input
              placeholder="No of sensor"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                numberInvalidCharacterPreventer(e);
              }}
              {...register("no_of_sensors", {
                // required: true,
              })}
            />
          
          </Col>
        </FormGroup> */}
        {/* <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Client timezone</Label>
          <Col>
            <select
              className="form-control"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              {...register("client_timezone", {
                required: true,
              })}
            >
              <option value="">-- Select Timezone --</option>
              {timeZone?.map((data, idx) => (
                <option value={data} key={idx}>
                  {data}
                </option>
              ))}
            </select>
            {errors.client_timezone?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup> */}
        {/* <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Client timezone</Label>
          <Col>
            <Select
              placeholder="Select TimeZone"
              options={timeZoneArray}
              onChange={setSelectedTimeZone}
              styles={customStyles}
              value={selectedTimeZone}
              isLoading={loading}
            />
          </Col>
        </FormGroup> */}

        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Industry *</Label>
          <Col>
            <Select
              placeholder="Select Industry"
              options={industryList}
              onChange={setSelectedIndustry}
              styles={customStyles}
              value={selectedIndustry}
            />
            {/* <select
              className="form-control"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              {...register("industry", {
                required: true,
              })}
            >
              <option value="">-- Select Industry --</option>
              {industryList?.map((data, idx) => (
                <option value={data.name} key={idx}>
                  {data.name}
                </option>
              ))}
            </select>
            {errors.industry?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )} */}
          </Col>
        </FormGroup>
        {selectedIndustry?.value === "Other Industry" && (
          <FormGroup style={{ marginBottom: "5px" }}>
            <Label sm={3}>Industry Name *</Label>
            <Col>
              <input
                placeholder="Industry Name"
                type="text"
                style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                className="form-control"
                {...register("industry", {
                  required: true,
                })}
              />
              {errors.industry?.type === "required" && (
                <span style={{ color: "red" }}>*This field is required</span>
              )}
            </Col>
          </FormGroup>
        )}

        {/* <FormGroup check style={{ marginBottom: "5px" }}>
          <Input
            id="checkbox2"
            type="checkbox"
            checked={isCloudChecked}
            onChange={(e) => setIsCloudChecked(e.target.checked)}
          />
          <Label check for="checkbox2">
            Cloud Sync Activated
          </Label>
        </FormGroup>

        {isCloudChecked && (
          <>
            <FormGroup style={{ marginBottom: "5px" }}>
              <Label sm={3}>Valid upto</Label>
              <Col>
                <select
                  className="form-control"
                  style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                  {...register("valid_upto", {
                    required: true,
                    onChange: (e) => {
                      changeHandler(e.target.value);
                    },
                  })}
                >
                  <option value="">-- Valid Upto --</option>
                  {yearArray.map((data, idx) => (
                    <option value={data} key={idx}>
                      {data + " Year"}
                    </option>
                  ))}
                </select>
                {errors.valid_upto?.type === "required" && (
                  <span style={{ color: "red" }}>*This field is required</span>
                )}
              </Col>
            </FormGroup>
            {validUptoDate && (
              <div>
                <p className="text-success">
                  Cloud Sync is valid from {moment().format("YYYY-MM-DD")} upto{" "}
                  {validUptoDate}
                </p>
              </div>
            )}
          </>
        )} */}

        {/* <hr />
        <u className="text-center">Invoice details :</u>

        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Invoice no</Label>
          <Col>
            <input
              placeholder="Invoice no"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("invoice_no", {})}
            />
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Invoice amount</Label>
          <Col>
            <input
              placeholder="Invoice amount"
              type="number"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) => {
                numberInvalidCharacterPreventer(e);
              }}
              {...register("invoice_amount", {
                required: true,
              })}
              //   onChange={handleNumInputChange}
            />
            {errors.invoice_amount?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Invoice date</Label>
          <Col>
            <input
              type="date"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("invoice_date", {
                required: true,
              })}
            />
            {errors.invoice_date?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Invoice attachment</Label>
          <Col>
            <input
              type="file"
              style={{ backgroundColor: "#FAFAFA" }}
              className="form-control"
              {...register("invoice_file", {})}
            />
          </Col>
        </FormGroup> */}

        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios || selectedIndustry === null}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
      {/* } */}
    </Modals>
  );
}

export default AddClient;
