import React, { useEffect } from "react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import Unauthorized from "../../containers/Pages/Error/Unauthorized";
import { authProtectedRoutes } from "../../routes/index";

export default function SidebarContent(props) {
  // const profile = useSelector((state) => state.authSlice.profile);
  const location = useLocation();

  useEffect(() => {
    new MetisMenu("#side-menu");

  }, []);

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{"Menu"}</li>
          {authProtectedRoutes.map((item, index) => {
            if (item.sidebar_presence) {
              return (
                <li key={index}>
                  {item.submenu ? (
                    <>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i
                          className={item.icon_class}
                          // style={{
                          //   color:
                          //     location.pathname === item.path
                          //       ? "white"
                          //       : "",
                          // }}
                        ></i>

                        <span
                          className="ms-1"
                          // style={{
                          //   color:
                          //     location.pathname === item.path
                          //       ? "white"
                          //       : "",
                          // }}
                        >
                          {item.name}
                        </span>
                      </Link>
                      <ul className="sub-menu">
                        {item.submenu_components.map((data, index) => (
                          <li key={index}>
                            <Link to={data.path} className="waves-effect">
                              <i
                                className={data.icon_class}
                                style={{
                                  color:
                                    location.pathname === data.path
                                      ? "white"
                                      : "",
                                }}
                              ></i>

                              <span
                                className="ms-1"
                                style={{
                                  color:
                                    location.pathname === data.path
                                      ? "white"
                                      : "",
                                }}
                              >
                                {data.name}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <Link to={item.path} className="waves-effect">
                        <i
                          className={item.icon_class}
                          style={{
                            color:
                                    location.pathname === item.path
                                      ? "white"
                                      : "",
                          }}
                        ></i>

                        <span
                          className="ms-1"
                          style={{
                            color:
                            location.pathname === item.path
                              ? "white"
                              : "",
                          }}
                        >
                          {item.name}
                        </span>
                      </Link>
                    </>
                  )}
                </li>
              );
            }
            return null
          })}
          <li></li>
        </ul>
      </div>
    </React.Fragment>
  );
}
