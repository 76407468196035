import React from "react";
import { FormGroup, Label } from "reactstrap";

const StartdateEndDate = ({
  register,
  errors,
  watch,
  setValue,
  startDate,
  endDate,
  startDateLabel,
  endDateLabel,
  is_Required,
  endDateHandler,
}) => {
  const eventStartDate = watch(startDate);
  return (
    <>
      <div className="row">
        <div className="col-6">
          <FormGroup>
            <Label sm={12}>
              {is_Required ? `${startDateLabel} *` : startDateLabel}
            </Label>
            <input
              type="date"
              min={new Date().toISOString().split("T")[0]}
              {...register(startDate, {
                required: is_Required,
                onChange:(e)=>endDateHandler(e.target.value),
                // onChange: (e) => {
                //   // setValue(endDate, "");
                //   endDateHandler(e.target.value);
                // },
              })}
              className="form-control"
              style={{ height: "40px" }}
              onKeyDown={(e) => e.preventDefault()}
            />
            {errors[startDate]?.type && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </FormGroup>
        </div>

        <div className="col-6">
          <FormGroup>
            <Label sm={12}>
              {is_Required ? `${endDateLabel} *` : endDateLabel}
            </Label>
            <input
              type="date"
              {...register(endDate, {
                required: is_Required,
              })}
              min={eventStartDate}
              // max={event_max_date}
              className="form-control"
              style={{ height: "40px" }}
              onKeyDown={(e) => e.preventDefault()}
            />
            {errors[endDate]?.type && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default StartdateEndDate;
