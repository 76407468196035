import fileDownload from "js-file-download";
import { ErrorAlert, Toastr } from "./Alert";
import { csvLimit, pdfLimit } from "./Constants";
import { pdfGenerator } from "./PDFReportGenerator";
import { authAxios } from "../axiosInstance";

export const exportData = (downloadType,download_url,totalCount,file_name) => {
    const url = `${download_url}&${downloadType}`;
    // setDownloadIndicator(true)
    if (downloadType === "pdf_download") {
      if (totalCount >= pdfLimit) {
        ErrorAlert(
          `The maximum records to export as PDF is ${pdfLimit}. Please apply filter to reduce the number of records`
        );
        return;
      }
      authAxios({
        url: url,
        method: "GET",
      })
        .then((response) => {
          const headRowData = [response.data?.header];
          const bodyRowData = response.data?.body;
          const reportName = "Service Request List";
          var doc = pdfGenerator(headRowData, bodyRowData, reportName);
          doc.save(`${file_name}.pdf`);
        })
        .catch((error) => {
          Toastr("error", "Error downloading the file !");
        });
    } else {
      if (totalCount >= csvLimit) {
        ErrorAlert(
          `The maximum records to export as CSV is ${csvLimit}. Please apply filter to reduce the number of records`
        );
        return;
      }
      authAxios({
        url: url,
        method: "GET",
        responseType: "blob", // Important
      })
        .then((response) => {
          let fileName = `${file_name}.csv`;
          fileDownload(response.data, fileName);
        })
        .catch((error) => {
          Toastr("error", "Error downloading the file !");
        });
    }
  };
