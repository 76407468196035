import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import Modals from "../../components/Modal";
import { authAxiosFileUpload } from "../../axiosInstance";
import Error from "../../helpers/Error";
import { ErrorAlert, Toastr } from "../../helpers/Alert";
import { numberInvalidCharacterPreventer } from "../../helpers/functions";
import { useDispatch, useSelector } from "react-redux";
import { updateClientDetail } from "../../store/client";
import GraphLoader from "../GraphLoader/GraphLoader";
import axios from "axios";
import { useParams } from "react-router-dom";
import useUnAuthorizedHandler from "../../hooks/useUnAuthorizedHandler";

function InitiateNewOrder(props) {
  const logoutHandler = useUnAuthorizedHandler();
  const clientDetail = useSelector((state) => state.ClientSlice.clientDetail);
  const totalSitesLabel =
    clientDetail?.total_no_of_sites === 0
      ? "Please Add Required Number of Sites"
      : `${clientDetail?.total_no_of_sites} Sites Are Added ,Please Add Additional Sites Required`;

  const totalSensorsLabel =
    clientDetail?.total_no_of_sensors === 0
      ? "Please Add Required Number of Sensors"
      : `${clientDetail?.total_no_of_sensors} Sensors Are Added ,Please Add Additional Sensors Required`;

  const cloudSyncLabel =
    clientDetail?.cloud_sync_valid_up_to === null
      ? "Please provide a Cloud Sync expiry date"
      : `Cloud Sync is valid up to - ${clientDetail?.cloud_sync_valid_up_to} , Please Provide an extended date in order to extend the validity`;

  const [orderType, setOrderType] = useState(null);
  const [waitingForAxios, setwaitingForAxios] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isFirstOrder, setIsFirstOrder] = useState(true);

  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    // setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data.expiry_date);
    setwaitingForAxios(true);
    let formData = new FormData();

    formData.append("order_type", orderType.value);
    formData.append("client", id);
    formData.append("amount", data.amount);

    formData.append(
      "no_of_sites",
      data.no_of_sites !== "" ? data.no_of_sites : 0
    );
    formData.append(
      "no_of_sensors",
      data.no_of_sensors !== "" ? data.no_of_sensors : 0
    );
    if (orderType.value === "Cloud" || orderType.value === "Both") {
      formData.append("cloud_sync_valid_up_to", data.expiry_date);
    }

    // if(orderType.label === 'On-premises'){
    //     formData.append("no_of_sites", data.no_of_sites);
    //     formData.append("no_of_sensors", data.no_of_sensors);
    //     formData.append("cloud_sync_valid_up_to", '');
    // }
    // else if(orderType.label === 'Cloud Sync'){
    //     formData.append("cloud_sync_valid_up_to", data.expiry_date);
    //     formData.append("no_of_sites", 0);
    //     formData.append("no_of_sensors", 0);
    // }
    // else{
    //     formData.append("no_of_sites", data.no_of_sites);
    //     formData.append("no_of_sensors", data.no_of_sensors);
    //     formData.append("cloud_sync_valid_up_to", data.expiry_date);
    // }

    let url = "/abacicount/clientorderlistcreate";
    authAxiosFileUpload
      .post(url, formData)
      .then((response) => {
        // dispatch(addNewClients(response.data))
        // props.tableRef.current.onQueryChange();

        dispatch(
          updateClientDetail({
            ...response.data,
            total_no_of_sensors: response.data.no_of_sensors,
            total_no_of_sites: response.data.no_of_sites,
            latest_product_activation_key: response.data.product_activation_key,
          })
        );
        if (props.activeTab === 4 || props.activeTab === 0) {
          props.tableRef.current.onQueryChange();
        }
        setwaitingForAxios(false);
        Toastr("success", "New Order Successfully Created!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error, logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  useEffect(() => {
    let url = `/abacicount/is_client_first_order?client=${id}`;
    axios
      .get(url)
      .then((response) => {
        setLoader(false);
        setIsFirstOrder(response.data.is_first_order);
        if (!response.data.is_first_order) {
          setOrderType({ value: "On-prem", label: "Device License" });
        }
      })
      .catch((error) => {
        setLoader(false);

        let error_msg = Error(error, logoutHandler);
        ErrorAlert(error_msg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)} style={{ margin: "20px" }}>
        <div style={{ minHeight: "400px" }}>
          {loader ? (
            <GraphLoader />
          ) : (
            <>
              {" "}
              <FormGroup style={{ marginBottom: "5px" }}>
                <Label sm={3}>Order Type</Label>
                <Col>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select Order Type"
                    isClearable={false}
                    isSearchable={false}
                    // value={}
                    onChange={(value) => {
                      setOrderType(value);
                      reset({
                        expiry_date: "",
                        no_of_sensors: "",
                        no_of_sites: "",
                      });
                    }}
                    value={orderType}
                    isDisabled={!isFirstOrder}
                    options={[]}
                  />
                </Col>
              </FormGroup>
              {orderType !== null && (
                <FormGroup style={{ marginBottom: "5px" }}>
                  <Label sm={12}>Amount</Label>
                  <Col>
                    <input
                      placeholder="Amount"
                      type="number"
                      style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                      className="form-control"
                      {...register("amount", {
                        required: true,
                      })}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) => {
                        numberInvalidCharacterPreventer(e);
                      }}
                    />
                    {errors.amount?.type === "required" && (
                      <span style={{ color: "red" }}>
                        *This field is required
                      </span>
                    )}
                  </Col>
                </FormGroup>
              )}
              {orderType?.label === "Device License" ? (
                <>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{totalSitesLabel}</Label>
                    <Col>
                      <input
                        placeholder="No Of Sites"
                        type="number"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("no_of_sites", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sites?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{totalSensorsLabel}</Label>
                    <Col>
                      <input
                        placeholder="No Of Sensors"
                        type="number"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("no_of_sensors", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sensors?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                </>
              ) : orderType?.label === "Cloud Sync" ? (
                <>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{cloudSyncLabel}</Label>
                    <Col>
                      <input
                        placeholder="Validity Date"
                        type="date"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("expiry_date", {
                          required: true,
                        })}
                      />
                      {errors.expiry_date?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                </>
              ) : orderType?.label === "Both" ? (
                <>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{totalSitesLabel}</Label>
                    <Col>
                      <input
                        placeholder="No Of Sites"
                        type="number"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("no_of_sites", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sites?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{totalSensorsLabel}</Label>
                    <Col>
                      <input
                        placeholder="No Of Sensors"
                        type="number"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("no_of_sensors", {
                          required: true,
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          numberInvalidCharacterPreventer(e);
                        }}
                      />
                      {errors.no_of_sensors?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup style={{ marginBottom: "5px" }}>
                    <Label sm={12}>{cloudSyncLabel}</Label>
                    <Col>
                      <input
                        placeholder="Validity Date"
                        type="date"
                        style={{ backgroundColor: "#FAFAFA", height: "45px" }}
                        className="form-control"
                        {...register("expiry_date", {
                          required: true,
                        })}
                      />
                      {errors.expiry_date?.type === "required" && (
                        <span style={{ color: "red" }}>
                          *This field is required
                        </span>
                      )}
                    </Col>
                  </FormGroup>
                </>
              ) : null}
            </>
          )}
        </div>

        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios || orderType === null}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modals>
  );
}

export default InitiateNewOrder;
