import React, { useEffect, useRef, useState } from "react";
import { exportData } from "../../../helpers/fileDownload";
import { authAxios } from "../../../axiosInstance";
import { tableIcons } from "../../../helpers/MaterialIcons";
import MaterialTable from "material-table";
import { useParams } from "react-router-dom";
import Moments from "../../../helpers/Moment";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import Error from "../../../helpers/Error";
import { Toastr } from "../../../helpers/Alert";
import AttachmentIcon from "@mui/icons-material/Attachment";
import moment from "moment";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";
import ReportInfoModal from "./reportInfoModal";
function TableComponent({ activeTab, tableRef }) {
  const logoutHandler = useUnAuthorizedHandler();
  const profile = useSelector((state) => state.authSlice.profile);
  const OrderType = {
    "On-prem": "Device License",
    Upgrade: "Upgrade",
    Both: "Both",
    Cloud: "Cloud Sync",
  };
  const { id } = useParams();
  const deviceDetail = useSelector((state) => state.ClientSlice.deviceDetail);
  const urlBackup = useRef("");
  const [reportModalShow, setReportShow] = useState(false);
  const [reportFiles, setReportFiles] = useState(null);
  const totalRecordsCount = useRef(0);
  const [payload, setPayload] = useState({});
  const [loader, setLoader] = useState(false);
  //   const profile = useSelector((state) => state.authSlice.profile);

  const reportModalToggle = (data, isFromTable) => {
    setReportShow(!reportModalShow);

    setReportFiles(isFromTable ? data : null);
  };

  useEffect(() => {
    if (deviceDetail !== null) {
      const token = Cookies.get("token");
      setPayload({
        request_type: "get",
        cloud_access_unique_number: deviceDetail.cloud_access_unique_number,
        is_public_url: false,
        token: token,
      });
    }
  }, [deviceDetail]);

  const UpdateHandler = (id) => {
    setLoader(true);
    const url = `/abacicount/deviceorderrud/${id}`;
    authAxios
      .patch(url, { status: "Cancelled" })
      .then((response) => {
        Toastr("success", "The Invoice order cancelled");
        setLoader(false);
        tableRef.current.onQueryChange();
        window.location.reload();
      })
      .catch((error) => {
        setLoader(false);

        const err_msg = Error(error, logoutHandler);
        Toastr("error", err_msg);
      });
  };
  const tableData = [
    {
      method: "get",
      url: `deviceinfolist/${id}`,
      column: [
        {
          title: "Sl No",
          field: "sl_no",
          width: "10%",
        },
        {
          title: "Log",
          field: "log",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => <>{Moments(rowData.created_date, "datetime")}</>,
        },
      ],
    },
    {
      method: "post",
      url: `/abacicount/cloudaccesstoclient`,
      payload: { ...payload, url: `/abaci_count/site` },
      column: [
        {
          title: "Sl No",
          field: "sl_no",
          width: "10%",
        },
        {
          title: "Site name",
          field: "site_name",
        },
        {
          title: "Rental Space",
          field: "rental_space",
        },
        {
          title: "Total capacity",
          field: "total_capacity",
        },
        {
          title: "Threshold",
          field: "threshold",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => <>{Moments(rowData.created_date, "datetime")}</>,
        },
      ],
    },
    {
      method: "post",
      payload: { ...payload, url: `/abaci_count/zones` },
      url: `/abacicount/cloudaccesstoclient`,
      column: [
        {
          title: "Zone name",
          field: "zone_name",
          render: (rowData) => (
            <>{rowData.zone_name ? rowData.zone_name : "----"}</>
          ),
        },
        {
          title: "Total Area(sqm)",
          field: "rental_space",
          render: (rowData) => (
            <>{rowData.rental_space ? rowData.rental_space : "----"}</>
          ),
        },
        {
          title: " Total Capacity(Person)",
          field: "total_capacity",
          render: (rowData) => (
            <>{rowData.total_capacity ? rowData.total_capacity : "----"}</>
          ),
        },
        {
          title: "Allowed Capacity(Person)",
          field: "threshold",
          render: (rowData) => (
            <>{rowData.threshold ? rowData.threshold : "----"}</>
          ),
        },
        {
          title: "Start Time",
          field: "start_time_formated",
          render: (rowData) => (
            <>
              {rowData.start_time
                ? moment(rowData.start_time, "HH:mm:ss").format("h:mmA")
                : "----"}
            </>
          ),
        },
        {
          title: "End Time",
          field: "end_time_formated",
          render: (rowData) => (
            <>
              {rowData.end_time
                ? moment(rowData.end_time, "HH:mm:ss").format("h:mmA")
                : "----"}
            </>
          ),
        },
      ],
    },
    {
      method: "post",
      url: `/abacicount/cloudaccesstoclient`,
      payload: { ...payload, url: `/abaci_count/sensors` },
      column: [
        {
          title: "Sensor name",
          field: "sensor_name",
          render: (rowData) => (
            <>{rowData.sensor_name ? rowData.sensor_name : "----"}</>
          ),
        },
        {
          title: "Serial no",
          field: "serial_number",
          render: (rowData) => (
            <>{rowData.serial_number ? rowData.serial_number : "----"}</>
          ),
        },
        {
          title: "Ip address",
          field: "ip_address",
          render: (rowData) => (
            <>{rowData.ip_address ? rowData.ip_address : "----"}</>
          ),
        },
        {
          title: "Mac address",
          field: "mac_add",
          render: (rowData) => (
            <>{rowData.mac_add ? rowData.mac_add : "----"}</>
          ),
        },
        {
          title: "Port",
          field: "port",
          render: (rowData) => <>{rowData.port ? rowData.port : "----"}</>,
        },
        {
          title: "Boundrary sensor",
          field: "is_site_boundary_sensor",
          render: (rowData) => (
            <>{rowData.is_site_boundary_sensor ? "Yes" : "No"}</>
          ),
        },
      ],
    },
    {
      method: "get",
      url: `deviceorderlist/${id}`,
      column: [
        {
          title: "Ref No",
          field: "reference_number",
          render: (rowData) => (
            <>{rowData.reference_number ? rowData.reference_number : "----"}</>
          ),
        },

        {
          title: "No Of Sites",
          field: "no_of_sites",
        },
        {
          title: "No Of Sensors",
          field: "no_of_sensors",
        },
        {
          title: "Order Type",
          field: "order_type",
          render: (rowData) => (
            <>{rowData.order_type ? OrderType[rowData.order_type] : "----"}</>
          ),
        },
        {
          title: "Version",
          render: (rowData) => (
            <>{rowData?.version?.version ? rowData.version.version : "----"}</>
          ),
        },
        {
          title: "Purchase Date",
          field: "created_date",
          render: (rowData) => (
            <>
              {rowData.created_date
                ? Moments(rowData.created_date, "datetime")
                : "----"}
            </>
          ),
        },
        {
          title: "Amount",
          field: "amount",
        },
        {
          title: "Status",
          field: "status",
        },
        // {
        //   title: "Invoice number",
        //   field: "invoice_number",
        //   render: (rowData) => (
        //     <>{rowData?.invoice_number ? rowData?.invoice_number : "----"}</>
        //   ),
        // },
        // {
        //   title: "Invoice amount",
        //   field: "invoice_amount",
        //   render: (rowData) => (
        //     <>{rowData?.invoice_amount ? rowData?.invoice_amount : "----"}</>
        //   ),
        // },
        // {
        //   title: "Invoice date",
        //   field: "invoice_date",
        //   render: (rowData) => (
        //     <>
        //       {rowData?.invoice_date ? Moments(rowData.invoice_date) : "----"}
        //     </>
        //   ),
        // },
        // {
        //   title: "",
        //   field: "",
        //   render: (rowData) => (
        //     <>
        //       <div style={{ display: rowData.invoice_file === null && "none" }}>
        //         <Tooltip title="Download Invoice">
        //           <IconButton
        //             onClick={() => downloadHandler(rowData.invoice_file)}
        //           >
        //             <DownloadIcon />
        //           </IconButton>
        //         </Tooltip>
        //       </div>
        //     </>
        //   ),
        // },
      ],
    },
    {
      method: "get",
      url: `reportlog/${id}`,
      column: [
        {
          title: "Sl No",
          field: "sl_no",
          width: "10%",
        },
        {
          title: "Log",
          field: "log",
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => <>{Moments(rowData.created_date, "datetime")}</>,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {reportModalShow && (
        <ReportInfoModal
          isOpen={reportModalShow}
          toggle={reportModalToggle}
          reportFiles={reportFiles}
          title={"Report Attachments"}
        />
      )}
      <div style={{ overflowY: "auto" }}>
        <MaterialTable
          title=" "
          columns={tableData[activeTab].column}
          tableRef={tableRef}
          isLoading={loader}
          data={(query) => {
            return new Promise((resolve, reject) => {
              let orderBy = "";
              let url = "";
              let ordering = "";
              let search = "";
              if (query.search) {
                search = query.search;
              }

              if (query.orderBy && (activeTab === 0 || activeTab === 4)) {
                orderBy =
                  query.orderDirection === "asc"
                    ? `&ordering=-${query.orderBy?.field}`
                    : `&ordering=${query.orderBy?.field}`;
              } else {
                orderBy =
                  query.orderDirection === "asc"
                    ? (ordering = `-${query.orderBy?.field}`)
                    : (ordering = query.orderBy?.field);
              }

              if (activeTab === 0 || activeTab === 4 || activeTab === 5) {
                url = `/abacicount/${tableData[activeTab].url}?limit=${
                  query.pageSize
                }&offset=${query.pageSize * query.page}&search=${
                  query.search
                }${orderBy}${activeTab === 4 ? `&client=${id}` : ""}`;
              } else {
                url = "/abacicount/cloudaccesstoclient";
              }

              urlBackup.current = url;

              authAxios[tableData[activeTab].method](
                url,
                (activeTab !== 0 || activeTab !== 4 || activeTab !== 5) && {
                  ...tableData[activeTab].payload,
                  url_params: {
                    offset: query.pageSize * query.page,
                    search: search,
                    limit: query.pageSize,
                    ordering: ordering,
                  },
                }
              )
                .then((response) => {
                  totalRecordsCount.current = response.data?.count;
                  const tempList = response.data?.results.map((data, idx) => ({
                    ...data,
                    sl_no: idx + 1,
                  }));

                  resolve({
                    data: tempList,
                    page: query.page,
                    totalCount: response.data?.count,
                  });
                })
                .catch((error) => {
                  const err_msg = Error(error, logoutHandler);
                  Toastr("error", err_msg);
                  reject({
                    data: [],
                    page: query.page,
                    totalCount: 0,
                  });
                });
            });
          }}
          actions={[
            (rowData) => ({
              icon: CancelIcon,
              tooltip: "Cancel",
              isFreeAction: true,
              onClick: (event, rowData) => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Cancel it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    UpdateHandler(rowData.id);
                    // deleteClientData(rowData?.id)
                  }
                });
                // setDeleteModal({ id: rowData?.id, toggle: true });
              },
              hidden:
                rowData.status === "Cancelled" ||
                activeTab !== 4 ||
                profile.user_type !== "Admin" ||
                deviceDetail?.status !== "Active",
            }),
            {
              icon: AttachmentIcon,
              tooltip: "View Attachments",
              onClick: (event, rowData) => {
                reportModalToggle(rowData.files_log, true);
              },
              hidden: activeTab !== 5,
            },
          ]}
          options={{
            exportFileName: "Users List",
            exportAllData: true,
            actionsColumnIndex: -1,
            exportButton: true,
            headerStyle: {
              zIndex: 0,
            },
            exportCsv: async () =>
              exportData(
                "csv_download",
                urlBackup.current,
                totalRecordsCount.current,
                "Client List"
              ),
            exportPdf: async () =>
              exportData(
                "pdf_download",
                urlBackup.current,
                totalRecordsCount.current,
                "Client List"
              ),
            debounceInterval: 500,
            filtering: false,
          }}
          icons={tableIcons}
        />
      </div>
    </React.Fragment>
  );
}

export default TableComponent;
