import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Modals from "../../../components/Modal";
import { authAxiosFileUpload } from "../../../axiosInstance";
import Error from "../../../helpers/Error";
import { ErrorAlert, Toastr } from "../../../helpers/Alert";
import useUnAuthorizedHandler from "../../../hooks/useUnAuthorizedHandler";

function AddUser(props) {

  const logoutHandler = useUnAuthorizedHandler();

  const [waitingForAxios, setwaitingForAxios] = useState(false);
  // const [confirmPassword, setConfirmPassword] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const passwordsMatch = () => {
    return watch("password") === watch("confirm_password");
  };

  const onSubmit = async (data) => {
    setwaitingForAxios(true);
    let formData = new FormData();

    // formData.append("country", data.country);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("user_type", data.user_type);
    let url = "/users_api/userlistcreate";
    authAxiosFileUpload
      .post(url, formData)
      .then((response) => {
        // dispatch(addNewClients(response.data))
        props.tableRef.current.onQueryChange();
        setwaitingForAxios(false);
        Toastr("success", "New user created successfully!!");
        props.toggle();
      })
      .catch((error) => {
        setwaitingForAxios(false);
        let error_msg = Error(error,logoutHandler);
        ErrorAlert(error_msg);
      });
  };

  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>First name</Label>
          <Col>
            <input
              placeholder="First name"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("first_name", {
                required: true,
              })}
            />
            {errors.first_name?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "5px" }}>
          <Label sm={3}>Last name</Label>
          <Col>
            <input
              placeholder="Last name"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("last_name", {
                required: true,
              })}
            />
            {errors.last_name?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "10px" }}>
          <Label sm={3}>Email</Label>
          <Col>
            <input
              placeholder="Email"
              type="text"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
            {errors.email?.type === "pattern" && (
              <span
                style={{
                  color: "red",
                }}
              >
                Please provide a valid email address
              </span>
            )}
          </Col>
        </FormGroup>

        <FormGroup style={{ marginBottom: "10px" }}>
          <Label for="Password" sm={3}>
            Password
          </Label>
          <Col>
            <input
              placeholder="Password"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              type="password"
              className="form-control"
              {...register("password", {
                required: true,
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              })}
            />
            {errors.password?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
            <span style={{ color: "red" }}>
              {errors.password?.type === "pattern"
                ? "The password should contain minimum 8 characters and maximum of 20 with a mix of alphanumeric,atleast 1 Uppercase letter and special characters"
                : null}
            </span>
          </Col>
        </FormGroup>
        <FormGroup style={{ marginBottom: "10px" }}>
          <Label for="Confirm Password" sm={3}>
            Confirm Password
          </Label>
          <Col>
            <input
              placeholder="Confirm Password"
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              type="password"
              className="form-control"
              {...register("confirm_password", {
                required: true,
              })}
            />
            {errors.confirm_password?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
            <span style={{ color: "red" }}>
              {!passwordsMatch() && "Passwords should match"}
            </span>
          </Col>
        </FormGroup>
        <Label for="User Type" sm={3}>
          User Type
        </Label>
        <FormGroup row style={{ marginBottom: "10px" }}>
          <Col>
            <select
              style={{ backgroundColor: "#FAFAFA", height: "45px" }}
              className="form-control"
              {...register("user_type", {
                required: true,
              })}
            >
              <option value=""> --Select User Type--</option>

              <option value="Admin">Admin</option>
              <option value="Viewer">Viewer</option>
            </select>

            {errors.user_type?.type === "required" && (
              <span style={{ color: "red" }}>*This field is required</span>
            )}
          </Col>
        </FormGroup>

        <ModalFooter>
          <Button
            type="submit"
            disabled={waitingForAxios || !passwordsMatch()}
            className="w-xs waves-effect waves-light me-1"
          >
            {waitingForAxios ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-xs waves-effect waves-light me-1"
            onClick={props.toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
      {/* } */}
    </Modals>
  );
}

export default AddUser;
