import React from "react";
import Modals from "../../../components/Modal";

function ReleaseVersionDetails(props) {
  return (
    <Modals
      isOpen={props.isOpen}
      toggle={props.toggle}
      title={props.title}
      size={props.size}
    >
      <div style={{ minHeight: "300px" }}></div>
    </Modals>
  );
}

export default ReleaseVersionDetails;
