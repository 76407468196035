import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogOut } from "../store/auth";

const useUnAuthorizedHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOutHandler = () => {
    dispatch(setLogOut());
    navigate("/login");
    localStorage.clear();
  };

  return logOutHandler;
};

export default useUnAuthorizedHandler;
